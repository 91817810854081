import React from 'react';
import { Text, Flex, Checkbox } from '@radix-ui/themes';

function CheckboxList({ name, options, onChange, value }) {
    const [val, setVal] = React.useState({});
    const [loading, setLoading] = React.useState({ [name]: false });

    React.useEffect(() => {
        if (value?.length > 0 && !(loading?.[name] === true)) {
            const newState = {};
            const parts = value?.split('-');
            parts.forEach((x) => {
                newState[x] = true;
            });
            setVal(newState);
            setLoading(true);
        }
    }, [value, loading]);

    const onValChange = (v, name) => {
        const newValue = { ...val };
        newValue[name] = v;
        if (v === false) {
            delete newValue[name];
        }
        setVal(newValue);
        onChange(Object.keys(newValue).sort().join('-'));
    };

    const isChecked = (option) => {
        return val?.[option] === true ? true : false;
    };

    return (
        <>
            {options.map((option, idx) => (
                <Text as="label" size="2" key={`checkbox-data-text-${idx}`}>
                    <Flex gap="2">
                        <Checkbox
                            key={`checkbox-data-${idx}`}
                            size="1"
                            checked={isChecked(option)}
                            onCheckedChange={(v) => onValChange(v, option)}
                        />{' '}
                        {option}
                    </Flex>
                </Text>
            ))}
        </>
    );
}

export default CheckboxList;
