import { useEffect, useState } from 'react';
import { authenticatedFetch } from '../../helpers/fetch';
import Loading from 'react-fullscreen-loading';
import { useAuth } from '@clerk/clerk-react';
import { Bar } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    ArcElement,
    Title,
    Tooltip,
    Legend,
    CategoryScale,
    LinearScale,
    PointElement,
    BarElement,
} from 'chart.js';
import { Flex, Box, Table } from '@radix-ui/themes';

ChartJS.register(
    ArcElement,
    Title,
    Tooltip,
    Legend,
    CategoryScale,
    LinearScale,
    PointElement,
    BarElement
);

function ResidenceAndEmploymentSituatation({ customerData }) {
    const { getToken, isLoaded, isSignedIn } = useAuth();

    const [housingData, setHousingData] = useState({ data: [], isLoading: false });
    const [employmentData, setEmploymentData] = useState({ data: [], isLoading: false });
    const [housingList, setHousingList] = useState([]);
    const [otherList, setOtherList] = useState([]);
    const [housingChartData, setHousingChartData] = useState();
    const [employmentList, setEmploymentList] = useState([]);
    const [employmentOtherList, setEmploymentOtherList] = useState([]);
    const [employmentChartData, setEmploymentChartData] = useState();

    useEffect(() => {
        const load = async () => {
            if (isLoaded && isSignedIn) {
                setHousingData({ data: [], isLoading: true });
                authenticatedFetch(`${window.location.origin}/api/lookup/housing`, await getToken())
                    .then((res) => res.json())
                    .then((data) => {
                        setHousingData({ data: data, isLoading: false });
                    });
            }
        };
        load();
    }, [isLoaded, isSignedIn]);

    useEffect(() => {
        const load = async () => {
            if (isLoaded && isSignedIn) {
                setEmploymentData({ data: [], isLoading: true });
                authenticatedFetch(
                    `${window.location.origin}/api/lookup/employment`,
                    await getToken()
                )
                    .then((res) => res.json())
                    .then((data) => {
                        setEmploymentData({ data: data, isLoading: false });
                    });
            }
        };
        load();
    }, [isLoaded, isSignedIn]);

    useEffect(() => {
        if (housingData?.data?.length > 0 && customerData?.data?.length > 0) {
            const housingList = housingData?.data?.map((a) => {
                return { count: 0, percent: 0, sum: 0, id: a.id, label: a.name };
            });
            customerData?.data?.map((customer) => {
                const exists = housingList.findIndex((b) => customer.housing_id === b.id);
                if (exists > -1) {
                    housingList[exists].count++;
                }
            });

            const sum = housingList.reduce((acc, o) => acc + parseInt(o.count), 0);

            housingList.forEach((b) => {
                b.percent = b.count === 0 ? 0 : Math.ceil((b.count / sum) * 100);
                b.sum = sum;
            });

            setHousingList(housingList);
        }
    }, [housingData, customerData]);

    useEffect(() => {
        if (employmentData?.data?.length > 0 && customerData?.data?.length > 0) {
            const employmentList = employmentData?.data?.map((a) => {
                return { count: 0, percent: 0, sum: 0, id: a.id, label: a.name };
            });
            customerData?.data?.map((customer) => {
                const exists = employmentList.findIndex((b) => customer.employment_id === b.id);
                if (exists > -1) {
                    employmentList[exists].count++;
                }
            });

            const sum = employmentList.reduce((acc, o) => acc + parseInt(o.count), 0);

            employmentList.forEach((b) => {
                b.percent = b.count === 0 ? 0 : Math.ceil((b.count / sum) * 100);
                b.sum = sum;
            });

            setEmploymentList(employmentList);
        }
    }, [employmentData, customerData]);

    useEffect(() => {
        if (customerData?.data?.length > 0) {
            const other = customerData.data
                .map((customer) => customer.housing_other)
                .filter((customer) => customer?.length > 0);
            setOtherList(other);
        }
    }, [customerData]);

    useEffect(() => {
        setHousingChartData({
            labels: housingList.map((a) => a.label),
            datasets: [
                {
                    label: 'Data',
                    data: housingList.map((a) => a.count),
                    backgroundColor: '#7ec7eb',
                },
            ],
        });
    }, [housingList]);

    useEffect(() => {
        if (customerData?.data?.length > 0) {
            const other = customerData.data
                .map((customer) => customer.employment_other)
                .filter((customer) => customer?.length > 0);
            setEmploymentOtherList(other);
        }
    }, [customerData]);

    useEffect(() => {
        setEmploymentChartData({
            labels: employmentList.map((a) => a.label),
            datasets: [
                {
                    label: 'Data',
                    data: employmentList.map((a) => a.count),
                    backgroundColor: '#7eeb9b',
                },
            ],
        });
    }, [employmentList]);

    const housingOpts = {
        plugins: {
            legend: {
                display: false,
                position: 'top',
            },
            title: {
                display: true,
                text: 'Where are you living at the moment?',
                color: 'black',
                font: {
                    family: 'Open Sans,Helvetica,Arial,sans-serif',
                    weight: '400',
                    size: 18,
                    lineHeight: 1.42857,
                },
            },
        },
    };

    const employmentOpts = {
        plugins: {
            legend: {
                display: false,
                position: 'top',
            },
            title: {
                display: true,
                text: 'Employment situation',
                color: 'black',
                font: {
                    family: 'Open Sans,Helvetica,Arial,sans-serif',
                    weight: '400',
                    size: 18,
                    lineHeight: 1.42857,
                },
            },
        },
    };

    if (housingData.isLoading || employmentData.isLoading)
        return <Loading loading loaderColor="#3498db" />;

    return (
        <>
            <Flex gap="3">
                <Box className="LeftColumn">
                    {housingList?.length > 0 && (
                        <Table.Root>
                            <Table.Body>
                                {housingList.map((a, idx) => (
                                    <Table.Row key={`report-key-${idx}`}>
                                        <Table.RowHeaderCell>{a.label}</Table.RowHeaderCell>
                                        <Table.Cell>{a.count}</Table.Cell>
                                        <Table.Cell>{a.percent}%</Table.Cell>
                                    </Table.Row>
                                ))}
                                <Table.Row>
                                    <Table.RowHeaderCell>&nbsp;</Table.RowHeaderCell>
                                    <Table.Cell>{housingList?.[0]?.sum}</Table.Cell>
                                    <Table.Cell>100%</Table.Cell>
                                </Table.Row>
                            </Table.Body>
                        </Table.Root>
                    )}
                </Box>
                <Box className="LeftColumn">
                    {otherList?.length > 0 && (
                        <Table.Root>
                            <Table.Body>
                                <Table.Row>
                                    <Table.RowHeaderCell>Other housing</Table.RowHeaderCell>
                                </Table.Row>
                                {otherList.map((a, idx) => (
                                    <Table.Row key={`report-h-other-${idx}`}>
                                        <Table.Cell>{a}</Table.Cell>
                                    </Table.Row>
                                ))}
                            </Table.Body>
                        </Table.Root>
                    )}
                </Box>
                <Box className="RightColumn" style={{ width: '700px' }}>
                    {housingChartData && <Bar data={housingChartData} options={housingOpts} />}
                </Box>
            </Flex>
            <Flex gap="3">
                <Box className="LeftColumn">
                    {employmentList?.length > 0 && (
                        <Table.Root>
                            <Table.Body>
                                {employmentList.map((a, idx) => (
                                    <Table.Row key={`report-e-${idx}`}>
                                        <Table.RowHeaderCell>{a.label}</Table.RowHeaderCell>
                                        <Table.Cell>{a.count}</Table.Cell>
                                        <Table.Cell>{a.percent}%</Table.Cell>
                                    </Table.Row>
                                ))}
                                <Table.Row>
                                    <Table.RowHeaderCell>&nbsp;</Table.RowHeaderCell>
                                    <Table.Cell>{employmentList?.[0]?.sum}</Table.Cell>
                                    <Table.Cell>100%</Table.Cell>
                                </Table.Row>
                            </Table.Body>
                        </Table.Root>
                    )}
                </Box>
                <Box className="LeftColumn">
                    {employmentOtherList?.length > 0 && (
                        <Table.Root>
                            <Table.Body>
                                <Table.Row>
                                    <Table.RowHeaderCell>Other employment</Table.RowHeaderCell>
                                </Table.Row>
                                {employmentOtherList.map((a, idx) => (
                                    <Table.Row key={`report-e-other-${idx}`}>
                                        <Table.Cell>{a}</Table.Cell>
                                    </Table.Row>
                                ))}
                            </Table.Body>
                        </Table.Root>
                    )}
                </Box>
                <Box className="RightColumn" style={{ width: '700px' }}>
                    {employmentChartData && (
                        <Bar data={employmentChartData} options={employmentOpts} />
                    )}
                </Box>
            </Flex>
        </>
    );
}

export default ResidenceAndEmploymentSituatation;
