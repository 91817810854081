import classNames from 'classnames';
import { Table, Button, Text } from '@radix-ui/themes';
import * as Tooltip from '@radix-ui/react-tooltip';
import PopupMenu from './PopupMenu';
import { ThickArrowLeftIcon, ThickArrowRightIcon } from '@radix-ui/react-icons';
import { format } from 'date-fns';
import { stringDateBetweenDays } from '../helpers/data';

function Headings({ ech }) {
    return (
        <>
            {ech?.map((weekday, idx) => (
                <Table.ColumnHeaderCell
                    className="DateHeading"
                    key={`customer-weekday-heading-${idx}`}
                >
                    {format(weekday, 'EEE do')}
                </Table.ColumnHeaderCell>
            ))}
        </>
    );
}

function AttendanceWeek({
    onDateClick,
    onDateChange,
    thisWeek,
    customers,
    attendanceDates,
    customerDates,
    dateStatusData,
}) {
    return (
        <Table.Root className={{ 'customer-data': true }}>
            <Table.Header>
                <Table.Row>
                    <Table.ColumnHeaderCell
                        rowSpan="2"
                        style={{ width: '30%', verticalAlign: 'middle' }}
                    >
                        Customer name
                    </Table.ColumnHeaderCell>
                    <Table.ColumnHeaderCell>&nbsp;</Table.ColumnHeaderCell>
                    <Table.ColumnHeaderCell
                        colSpan={thisWeek?.length + 1}
                        style={{ textAlign: 'center' }}
                    >
                        {stringDateBetweenDays(thisWeek)}
                    </Table.ColumnHeaderCell>
                </Table.Row>
                <Table.Row>
                    <Table.ColumnHeaderCell className="DateArrowHeading">
                        <Tooltip.Provider>
                            <Tooltip.Root>
                                <Tooltip.Trigger>
                                    <Button
                                        variant="soft"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            onDateChange('left');
                                        }}
                                    >
                                        <ThickArrowLeftIcon width="24" height="24" />
                                    </Button>
                                </Tooltip.Trigger>
                                <Tooltip.Portal>
                                    <Tooltip.Content className="TooltipContent" sideOffset={5}>
                                        View previous week
                                        <Tooltip.Arrow className="TooltipArrow" />
                                    </Tooltip.Content>
                                </Tooltip.Portal>
                            </Tooltip.Root>
                        </Tooltip.Provider>
                    </Table.ColumnHeaderCell>

                    <Headings ech={thisWeek} />

                    <Table.ColumnHeaderCell className="DateArrowHeading">
                        <Tooltip.Provider>
                            <Tooltip.Root>
                                <Tooltip.Trigger>
                                    <Button
                                        variant="soft"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            onDateChange('right');
                                        }}
                                    >
                                        <ThickArrowRightIcon width="24" height="24" />
                                    </Button>
                                </Tooltip.Trigger>
                                <Tooltip.Portal>
                                    <Tooltip.Content className="TooltipContent" sideOffset={5}>
                                        View next week
                                        <Tooltip.Arrow className="TooltipArrow" />
                                    </Tooltip.Content>
                                </Tooltip.Portal>
                            </Tooltip.Root>
                        </Tooltip.Provider>
                    </Table.ColumnHeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {customers.map((customer, idx) => (
                    <Table.Row key={`customer-key-${idx}`}>
                        <Table.RowHeaderCell>
                            {customer.last_name + ', ' + customer.first_name}
                            {(customer.dob || customer.gender) && (
                                <Text color="gray" size="1">
                                    <br />
                                    {customer.dob
                                        ? format(new Date(customer.dob), 'do MMMM yyyy') + ' / '
                                        : ''}
                                    {customer.gender === 'm' ? 'Male' : 'Female'}
                                </Text>
                            )}
                        </Table.RowHeaderCell>
                        <Table.Cell>&nbsp;</Table.Cell>
                        {thisWeek?.map((date, idx) => {
                            const hasCustomerDate = customerDates
                                ?.filter((customerDate) => customerDate.customer_id === customer.id)
                                .map((c) => c.date)
                                .includes(format(date, 'do MMMM yyyy'));

                            let funding;
                            if (hasCustomerDate) {
                                funding = customerDates?.find(
                                    (customerDate) =>
                                        customerDate.customer_id === customer.id &&
                                        customerDate.date == format(date, 'do MMMM yyyy')
                                )?.funding_name;
                            }

                            const attendanceDate = attendanceDates?.find(
                                (customerDate) =>
                                    customerDate.customer_id === customer.id &&
                                    customerDate.date == format(date, 'do MMMM yyyy')
                            );

                            const classes = classNames({
                                DateCell: true,
                                DateCellLast: thisWeek.length - 1 === idx,
                                DateCellOn: hasCustomerDate,
                                DateCellOnCouncil: funding === 'Council' ? true : false,
                            });

                            return (
                                <PopupMenu
                                    isMonth={false}
                                    onClick={(statusId, statusName) =>
                                        onDateClick(customer.id, date, statusId, statusName)
                                    }
                                    dateStatusData={{
                                        data: dateStatusData?.data.concat([
                                            { id: 99, name: 'Clear', ord: 99 },
                                        ]),
                                    }}
                                    attendanceDate={attendanceDate}
                                    classes={classes}
                                    key={`customer-attendance-status-${idx}`}
                                    funding={funding}
                                />
                            );
                        })}
                        <Table.Cell>&nbsp;</Table.Cell>
                    </Table.Row>
                ))}
            </Table.Body>
        </Table.Root>
    );
}

export default AttendanceWeek;
