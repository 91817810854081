import React from 'react';
import * as Select from '@radix-ui/react-select';
import { CheckIcon, ChevronDownIcon, ChevronUpIcon } from '@radix-ui/react-icons';
import classnames from 'classnames';
import '../styles/SelectElement.css';

/* eslint-disable react/display-name */
const SelectItem = React.forwardRef(({ children, className, ...props }, forwardedRef) => {
    return (
        <Select.Item className={classnames('SelectItem', className)} {...props} ref={forwardedRef}>
            <Select.ItemText>{children}</Select.ItemText>
            <Select.ItemIndicator className="SelectItemIndicator">
                <CheckIcon />
            </Select.ItemIndicator>
        </Select.Item>
    );
});

const valIsNull = (val) => {
    return [null, undefined, 'null', 'undefined', '0', 0].includes(val);
};

function SelectElement({ className, name, label, placeholder, data, value, disabled, onChange }) {
    const classNames = {
        SelectTrigger: true,
    };

    if (Array.isArray(className)) {
        className.forEach((c) => {
            classNames[c] = true;
        });
    } else if (className?.length > 0) {
        classNames[className] = true;
    }

    return (
        <Select.Root
            disabled={disabled}
            onValueChange={(val) => onChange(val, name)}
            defaultValue={valIsNull(value) ? undefined : value?.toString()}
        >
            <Select.Trigger className={classnames(classNames)}>
                <Select.Value
                    placeholder={
                        value?.toString().length > 0 && !valIsNull(value)
                            ? value.toString()
                            : placeholder
                    }
                />
                <Select.Icon className="SelectIcon">
                    <ChevronDownIcon />
                </Select.Icon>
            </Select.Trigger>
            <Select.Portal>
                <Select.Content className="SelectContent">
                    <Select.ScrollUpButton className="SelectScrollButton">
                        <ChevronUpIcon />
                    </Select.ScrollUpButton>

                    <Select.Viewport className="SelectViewport">
                        <Select.Group>
                            <Select.Label className="SelectLabel">{label}</Select.Label>
                            {data.map((val, idx) => {
                                let text = val;

                                // If data is array of objects rather just array of labels
                                if (val.label) {
                                    text = val.label;
                                }

                                return (
                                    <SelectItem
                                        value={text.toString()}
                                        key={`select-data-${idx}`}
                                        className={
                                            val.colour ? 'SelectItemColour' + val.colour : ''
                                        }
                                    >
                                        {text.toString()}
                                    </SelectItem>
                                );
                            })}
                        </Select.Group>
                    </Select.Viewport>

                    <Select.ScrollDownButton className="SelectScrollButton">
                        <ChevronDownIcon />
                    </Select.ScrollDownButton>
                </Select.Content>
            </Select.Portal>
        </Select.Root>
    );
}

export default SelectElement;
