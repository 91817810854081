import { useEffect, useState } from 'react';
import { authenticatedFetch } from './helpers/fetch';
import Loading from 'react-fullscreen-loading';
import { SignedIn, useAuth } from '@clerk/clerk-react';
import { subMonths } from 'date-fns';
import { Heading, Flex, Box, Text, Button } from '@radix-ui/themes';
import SignedOutLink from './components/SignedOut';
import Breadcrumb from './components/Breadcrumb';
import ScrollList from './components/ScrollList';
import SelectElement from './components/SelectElement';
import { months, fields } from './helpers/data.js';
import { createXslt } from './helpers/export.js';
import { useQuery } from './helpers/routing.js';
import './styles/Exports.css';
import { PinBottomIcon } from '@radix-ui/react-icons';
import fileDownload from 'js-file-download';
import { format } from 'date-fns';
import Filters from './components/Filters';

function Exports() {
    const links = [
        { url: '/customers', text: 'Customer Data' },
        { url: '/exports', text: 'Exports' },
    ];

    let query = useQuery();

    const { getToken, isLoaded, isSignedIn } = useAuth();

    const today = new Date();
    const aMonthsAgo = subMonths(today, 1);

    const lookups = [
        'country',
        'service',
        'funding',
        'marital',
        'adultAge',
        'childAge',
        'housing',
        'employment',
        'health',
        'energyTry',
        'bill',
        'datestatus',
    ];

    const lookupDataState = {};
    lookups.forEach((lookup) => {
        lookupDataState[lookup] = { data: [], isLoading: false };
    });

    const [debug] = useState(query.get('debug') > 0 ? true : false);

    const [dataLoading, setDataLoading] = useState(true);

    const [serviceData, setServiceData] = useState({ data: [], isLoading: false });
    const [customerData, setCustomerData] = useState({ data: [], isLoading: false });
    const [customerBillData, setCustomerBillData] = useState({ data: [], isLoading: false });
    const [customerEnergyTryData, setCustomerEnergyTryData] = useState({
        data: [],
        isLoading: false,
    });
    const [customerHealthData, setCustomerHealthData] = useState({ data: [], isLoading: false });
    const [customerAdultAgeData, setCustomerAdultAgeData] = useState({
        data: [],
        isLoading: false,
    });
    const [customerChildAgeData, setCustomerChildAgeData] = useState({
        data: [],
        isLoading: false,
    });

    const [currentLookup, setCurrentLookup] = useState();
    const [lookupData, setLookupData] = useState(lookupDataState);

    const [services, setServices] = useState([]);
    const [service, setService] = useState(localStorage.getItem('export_service') ?? undefined);
    const [selectedFields, setSelectedFields] = useState(
        localStorage.getItem('export_selectedfields')
            ? localStorage.getItem('export_selectedfields').split(',')
            : []
    );
    const [fromDay, setFromDay] = useState(
        localStorage.getItem('export_fromday')
            ? Number.parseInt(localStorage.getItem('export_fromday'), 10)
            : aMonthsAgo.getDate()
    );
    const [fromMonth, setFromMonth] = useState(
        localStorage.getItem('export_frommonth') ?? months[aMonthsAgo.getMonth()]
    );
    const [fromYear, setFromYear] = useState(
        localStorage.getItem('export_fromyear')
            ? Number.parseInt(localStorage.getItem('export_fromyear'), 10)
            : aMonthsAgo.getFullYear()
    );
    const [toDay, setToDay] = useState(
        localStorage.getItem('export_today')
            ? Number.parseInt(localStorage.getItem('export_today'), 10)
            : today.getDate()
    );
    const [toMonth, setToMonth] = useState(
        localStorage.getItem('export_tomonth') ?? months[today.getMonth()]
    );
    const [toYear, setToYear] = useState(
        localStorage.getItem('export_toyear')
            ? Number.parseInt(localStorage.getItem('export_toyear'), 10)
            : today.getFullYear()
    );
    const [downloading, setDownloading] = useState(false);
    const [activeInactive, setActiveInactive] = useState(
        localStorage.getItem('export_activeinactive') ?? 'Active'
    );

    useEffect(() => {
        setTimeout(() => {
            setDataLoading(false);
        }, 2000);
    }, []);

    useEffect(() => {
        const load = async () => {
            if (isLoaded && isSignedIn) {
                setServiceData({ data: [], isLoading: true });
                authenticatedFetch(
                    `${window.location.origin}/api/data/customer/0/services`,
                    await getToken()
                )
                    .then((res) => res.json())
                    .then((data) => {
                        setServiceData({ data: data, isLoading: false });
                    });
            }
        };
        load();
    }, [isLoaded, isSignedIn]);

    useEffect(() => {
        const load = async () => {
            if (isLoaded && isSignedIn) {
                setCustomerData({ data: [], isLoading: true });
                authenticatedFetch(`${window.location.origin}/api/data/customer`, await getToken())
                    .then((res) => res.json())
                    .then((data) => {
                        setCustomerData({ data: data, isLoading: false });
                    });
            }
        };
        load();
    }, [isLoaded, isSignedIn]);

    useEffect(() => {
        const load = async () => {
            if (isLoaded && isSignedIn) {
                setCustomerBillData({ data: [], isLoading: true });
                authenticatedFetch(
                    `${window.location.origin}/api/data/customer/0/bills`,
                    await getToken()
                )
                    .then((res) => res.json())
                    .then((data) => {
                        setCustomerBillData({ data: data, isLoading: false });
                    });
            }
        };
        load();
    }, [isLoaded, isSignedIn]);

    useEffect(() => {
        const load = async () => {
            if (isLoaded && isSignedIn) {
                setCustomerEnergyTryData({ data: [], isLoading: true });
                authenticatedFetch(
                    `${window.location.origin}/api/data/customer/0/energies`,
                    await getToken()
                )
                    .then((res) => res.json())
                    .then((data) => {
                        setCustomerEnergyTryData({ data: data, isLoading: false });
                    });
            }
        };
        load();
    }, [isLoaded, isSignedIn]);

    useEffect(() => {
        const load = async () => {
            if (isLoaded && isSignedIn) {
                setCustomerHealthData({ data: [], isLoading: true });
                authenticatedFetch(
                    `${window.location.origin}/api/data/customer/0/healths`,
                    await getToken()
                )
                    .then((res) => res.json())
                    .then((data) => {
                        setCustomerHealthData({ data: data, isLoading: false });
                    });
            }
        };
        load();
    }, [isLoaded, isSignedIn]);

    useEffect(() => {
        const load = async () => {
            if (isLoaded && isSignedIn) {
                setCustomerAdultAgeData({ data: [], isLoading: true });
                authenticatedFetch(
                    `${window.location.origin}/api/data/customer/0/ages/adultAge`,
                    await getToken()
                )
                    .then((res) => res.json())
                    .then((data) => {
                        setCustomerAdultAgeData({ data: data, isLoading: false });
                    });
            }
        };
        load();
    }, [isLoaded, isSignedIn]);

    useEffect(() => {
        const load = async () => {
            if (isLoaded && isSignedIn) {
                setCustomerChildAgeData({ data: [], isLoading: true });
                authenticatedFetch(
                    `${window.location.origin}/api/data/customer/0/ages/childAge`,
                    await getToken()
                )
                    .then((res) => res.json())
                    .then((data) => {
                        setCustomerChildAgeData({ data: data, isLoading: false });
                    });
            }
        };
        load();
    }, [isLoaded, isSignedIn]);

    useEffect(() => {
        const load = async () => {
            if (isLoaded && isSignedIn) {
                if (currentLookup !== 'end') {
                    if (!currentLookup) {
                        setCurrentLookup(lookups[0]);
                    } else {
                        const lk = { ...lookupData };
                        lk[currentLookup] = { data: [], isLoading: true };
                        setLookupData(lk);
                        authenticatedFetch(
                            `${window.location.origin}/api/lookup/${currentLookup}`,
                            await getToken()
                        )
                            .then((res) => res.json())
                            .then((data) => {
                                lk[currentLookup] = { data: data, isLoading: false };
                                setLookupData(lk);

                                const idx = lookups.findIndex((el) => el === currentLookup);
                                if (lookups.length - 1 === idx) {
                                    setCurrentLookup('end');
                                } else {
                                    setCurrentLookup(lookups[idx + 1]);
                                }
                            });
                    }
                }
            }
        };
        load();
    }, [isLoaded, isSignedIn, currentLookup]);

    useEffect(() => {
        setServices([...new Set(serviceData?.data?.map((record) => record.name) ?? [])].sort());
    }, [serviceData?.data]);

    useEffect(() => {
        const startDownload = async () => {
            if (downloading) {
                const buffer = await createXslt(
                    fields,
                    selectedFields,
                    {
                        dateFrom: { day: fromDay, month: fromMonth, year: fromYear },
                        dateTo: { day: toDay, month: toMonth, year: toYear },
                    },
                    service,
                    activeInactive,
                    {
                        customerData: customerData?.data ?? [],
                        serviceData: serviceData?.data ?? [],
                        customerBillData: customerBillData?.data ?? [],
                        customerEnergyTryData: customerEnergyTryData?.data ?? [],
                        customerHealthData: customerHealthData?.data ?? [],
                        customerAdultAgeData: customerAdultAgeData?.data ?? [],
                        customerChildAgeData: customerChildAgeData?.data ?? [],
                        lookupData,
                    },
                    localStorage.getItem('filters')
                        ? JSON.parse(localStorage.getItem('filters'))
                        : {},
                    debug,
                    getToken
                );
                if (buffer && !debug) {
                    fileDownload(
                        buffer,
                        `customer_data_extract_${format(new Date(), 'yyyyMMddhhmmss')}.xlsx`,
                        'application/vnd.ms-excel'
                    );
                    setDownloading(false);
                }
            }
        };
        startDownload();
    }, [downloading]);

    const onMoveAll = (name, all) => {
        switch (name) {
            case 'fieldList':
            case 'selectedList':
                if (all === true) {
                    const values = fields.map((fie) => fie.label);
                    setSelectedFields(values);
                    localStorage.setItem('export_selectedfields', values);
                } else {
                    setSelectedFields([]);
                    localStorage.setItem('export_selectedfields', []);
                }
                break;
        }
    };

    const onFieldsClick = (name, fie, add) => {
        if (add) {
            if (!selectedFields.includes(fie)) {
                const values = selectedFields.concat([fie]);
                setSelectedFields(values);
                localStorage.setItem('export_selectedfields', values);
            }
        } else {
            const cloned = [...selectedFields];
            const index = cloned.findIndex((i) => i === fie);

            if (index !== -1) {
                cloned.splice(index, 1);
            }

            setSelectedFields(cloned);
            localStorage.setItem('export_selectedfields', cloned);
        }
    };

    const onServiceChange = (val) => {
        setService(val);
        localStorage.setItem('export_service', val);
    };

    const onDateChange = (val, name) => {
        switch (name) {
            case 'from-day':
                setFromDay(val);
                localStorage.setItem('export_fromday', val);
                break;
            case 'from-month':
                setFromMonth(val);
                localStorage.setItem('export_frommonth', val);
                break;
            case 'from-year':
                setFromYear(val);
                localStorage.setItem('export_fromyear', val);
                break;
            case 'to-day':
                setToDay(val);
                localStorage.setItem('export_today', val);
                break;
            case 'to-month':
                setToMonth(val);
                localStorage.setItem('export_tomonth', val);
                break;
            case 'to-year':
                setToYear(val);
                localStorage.setItem('export_toyear', val);
                break;
        }
    };

    const onActiveChange = (val) => {
        setActiveInactive(val);
        localStorage.setItem('export_activeinactive', val);
    };

    if (dataLoading || downloading || customerData.isLoading || serviceData.isLoading)
        return <Loading loading loaderColor="#3498db" />;

    return (
        <>
            <SignedOutLink />
            <SignedIn>
                <div className="page-header">
                    <Heading>Exports</Heading>
                </div>

                <Breadcrumb links={links} />

                <Flex gap="3">
                    <Heading className="SubHeading">
                        <div className="circle">
                            <p>1</p>
                        </div>{' '}
                        Select which fields you would like to export
                    </Heading>
                </Flex>
                <Flex gap="3">
                    <ScrollList
                        name="fieldList"
                        label="Available Fields"
                        list={fields
                            .map((field) => field.label)
                            ?.filter((field) => !selectedFields?.includes(field))}
                        onClick={(name, ser) => onFieldsClick(name, ser, true)}
                        onMoveAll={(name) => onMoveAll(name, true)}
                    />
                    <ScrollList
                        name="selectedList"
                        rightList={true}
                        label="Selected Fields"
                        list={selectedFields}
                        onClick={(name, ser) => onFieldsClick(name, ser, false)}
                        onMoveAll={(name) => onMoveAll(name, false)}
                    />
                </Flex>

                <Flex gap="3">
                    <Heading className="SubHeading">
                        <div className="circle">
                            <p>2</p>
                        </div>{' '}
                        Select which service you would like to export
                    </Heading>
                </Flex>

                <Flex gap="3">
                    <Box className="Active">
                        <SelectElement
                            name="serviceList"
                            label="Service"
                            placeholder="Service"
                            data={services}
                            value={service}
                            onChange={onServiceChange}
                        />
                    </Box>
                </Flex>

                <Flex gap="3">
                    <Heading className="SubHeading">
                        <div className="circle">
                            <p>3</p>
                        </div>{' '}
                        Between which dates (if extracting attendance dates etc.)
                    </Heading>
                </Flex>

                <Flex gap="3">
                    <Box className="DatePickerBox">
                        <Text className="SubLabel">From</Text>
                        <SelectElement
                            name="from-day"
                            label="Day"
                            placeholder="Day"
                            data={Array.from(Array(31), (_, x) => x + 1)}
                            value={fromDay.toString()}
                            onChange={onDateChange}
                        />
                        <SelectElement
                            name="from-month"
                            label="Month"
                            placeholder="Month"
                            data={months}
                            value={fromMonth}
                            onChange={onDateChange}
                        />
                        <SelectElement
                            name="from-year"
                            label="Year"
                            placeholder="Year"
                            data={Array.from(
                                Array(100),
                                (_, x) => x + (new Date().getFullYear() - 99)
                            ).reverse()}
                            value={fromYear.toString()}
                            onChange={onDateChange}
                        />
                    </Box>
                    <Box className="DateBox">
                        <Text className="SubLabel">To</Text>
                        <SelectElement
                            name="to-day"
                            label="Day"
                            placeholder="Day"
                            data={Array.from(Array(31), (_, x) => x + 1)}
                            value={toDay.toString()}
                            onChange={onDateChange}
                        />
                        <SelectElement
                            name="to-month"
                            label="Month"
                            placeholder="Month"
                            data={months}
                            value={toMonth}
                            onChange={onDateChange}
                        />
                        <SelectElement
                            name="to-year"
                            label="Year"
                            placeholder="Year"
                            data={Array.from(
                                Array(100),
                                (_, x) => x + (new Date().getFullYear() - 99)
                            ).reverse()}
                            value={toYear.toString()}
                            onChange={onDateChange}
                        />
                    </Box>
                </Flex>

                <Flex gap="3">
                    <Heading className="SubHeading">
                        <div className="circle">
                            <p>4</p>
                        </div>{' '}
                        Would you like Active or Inactive customers?
                    </Heading>
                </Flex>

                <Flex gap="3">
                    <Box className="Active">
                        <SelectElement
                            name="active-inactive"
                            label="Active/Inactive"
                            placeholder="Active/Inactive"
                            data={['Active', 'Inactive', 'Both']}
                            value={activeInactive}
                            onChange={onActiveChange}
                        />
                    </Box>
                </Flex>

                <Flex gap="3">
                    <Heading className="SubHeading">
                        <div className="circle">
                            <p>5</p>
                        </div>{' '}
                        Add additional filters (optional)
                    </Heading>
                </Flex>

                <Filters />

                <Flex gap="3">
                    <Heading className="SubHeading">
                        <div className="circle">
                            <p>6</p>
                        </div>{' '}
                        Finally, export the data!
                    </Heading>
                </Flex>
                <Flex gap="3">
                    <Button
                        className="SubmitButton"
                        disabled={selectedFields?.length === 0}
                        type="submit"
                        onClick={(e) => {
                            e.preventDefault();
                            setDownloading(true);
                        }}
                    >
                        <PinBottomIcon width="16" height="16" />
                        Download
                    </Button>
                </Flex>
            </SignedIn>
        </>
    );
}

export default Exports;
