import { useEffect, useState } from 'react';
import { useAuth } from '@clerk/clerk-react';
import { Bar } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    ArcElement,
    Title,
    Tooltip,
    Legend,
    CategoryScale,
    LinearScale,
    PointElement,
    BarElement,
} from 'chart.js';
import { Flex, Box, Table } from '@radix-ui/themes';

ChartJS.register(
    ArcElement,
    Title,
    Tooltip,
    Legend,
    CategoryScale,
    LinearScale,
    PointElement,
    BarElement
);

function HelpWithMoneyAdviceBills({ customerData }) {
    const { isLoaded, isSignedIn } = useAuth();

    const workAssists = ['Yes', 'No', 'N/A', ''];

    const [workList, setWorkList] = useState([]);
    const [otherWorkList, setOtherWorkList] = useState([]);
    const [workChartData, setWorkChartData] = useState();

    useEffect(() => {
        if (isLoaded && isSignedIn) {
            const workList = workAssists.map((a) => {
                return { count: 0, percent: 0, sum: 0, label: a };
            });
            customerData?.data?.map((customer) => {
                const exists = workList.findIndex((b) => customer.work_related_help === b.label);
                if (exists > -1) {
                    workList[exists].count++;
                }
            });

            const sum = workList.reduce((acc, o) => acc + parseInt(o.count), 0);

            workList.forEach((b) => {
                b.percent = b.count === 0 ? 0 : Math.ceil((b.count / sum) * 100);
                b.sum = sum;
            });

            setWorkList(workList);
        }
    }, [customerData, isLoaded, isSignedIn]);

    useEffect(() => {
        setWorkChartData({
            labels: workList.map((a) => (a?.label?.length > 0 ? a.label : 'Not answered')),
            datasets: [
                {
                    label: 'Data',
                    data: workList.map((a) => a.count),
                    backgroundColor: '#7ec7eb',
                },
            ],
        });
    }, [workList]);

    const workOpts = {
        indexAxis: 'y',
        plugins: {
            legend: {
                display: false,
                position: 'top',
            },
            title: {
                display: true,
                text: 'Have you asked for help or advice?',
                color: 'black',
                font: {
                    family: 'Open Sans,Helvetica,Arial,sans-serif',
                    weight: '400',
                    size: 18,
                    lineHeight: 1.42857,
                },
            },
        },
    };

    useEffect(() => {
        if (customerData?.data?.length > 0) {
            const other = customerData.data
                .map((customer) => customer.work_related_help_other)
                .filter((customer) => customer?.length > 0);
            setOtherWorkList(other);
        }
    }, [customerData]);

    return (
        <>
            <Flex gap="3">
                <Box className="LeftColumn">
                    {workList?.length > 0 && (
                        <Table.Root>
                            <Table.Body>
                                {workList.map((a, idx) => (
                                    <Table.Row key={`report-key-${idx}`}>
                                        <Table.RowHeaderCell>
                                            {a?.label?.length > 0 ? a.label : 'Not answered'}
                                        </Table.RowHeaderCell>
                                        <Table.Cell>{a.count}</Table.Cell>
                                        <Table.Cell>{a.percent}%</Table.Cell>
                                    </Table.Row>
                                ))}
                                <Table.Row>
                                    <Table.RowHeaderCell>&nbsp;</Table.RowHeaderCell>
                                    <Table.Cell>{workList?.[0]?.sum}</Table.Cell>
                                    <Table.Cell>100%</Table.Cell>
                                </Table.Row>
                            </Table.Body>
                        </Table.Root>
                    )}
                </Box>
                <Box className="RightColumn" style={{ width: '800px' }}>
                    {workChartData && <Bar data={workChartData} options={workOpts} />}
                </Box>
            </Flex>
            <Flex gap="3">
                <Box className="LeftColumn">
                    {otherWorkList?.length > 0 && (
                        <Table.Root>
                            <Table.Body>
                                <Table.Row>
                                    <Table.RowHeaderCell>
                                        &quot;Yes&quot; reasons
                                    </Table.RowHeaderCell>
                                </Table.Row>
                                {otherWorkList.map((a, idx) => (
                                    <Table.Row key={`report-other-${idx}`}>
                                        <Table.Cell>{a}</Table.Cell>
                                    </Table.Row>
                                ))}
                            </Table.Body>
                        </Table.Root>
                    )}
                </Box>
            </Flex>
        </>
    );
}

export default HelpWithMoneyAdviceBills;
