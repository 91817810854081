import React from 'react';
import '../styles/ThreeStateCheckbox.css';

function ThreeStateCheckbox({ checked, onCheckedChange }) {
    const inputElement = React.useRef();

    const States = ['False', 'N/A', 'True'];

    const [loaded, setLoaded] = React.useState(false);
    const [state, setState] = React.useState(checked);

    setTimeout(() => setLoaded(true), 1000);

    function toggleState() {
        let idx = States.findIndex((State) => State == state);
        setState(States[++idx % 3]);
    }

    React.useEffect(() => {
        setState(checked);
    }, [checked]);

    React.useEffect(() => {
        if (state == 'False') {
            inputElement.current.indeterminate = true;
            inputElement.current.checked = true;
        } else if (state == 'True') {
            inputElement.current.indeterminate = false;
            inputElement.current.checked = true;
        } else {
            inputElement.current.indeterminate = false;
            inputElement.current.checked = false;
        }

        if (loaded) {
            const val = States.find((State) => State == state);
            onCheckedChange(val);
        }
    }, [state]);

    return (
        <input
            name="name"
            ref={inputElement}
            type="checkbox"
            className="ThreeStateCheckbox"
            onChange={(e) => toggleState(e)}
        />
    );
}

export default ThreeStateCheckbox;
