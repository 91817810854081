import { useEffect, useState } from 'react';
import { useAuth } from '@clerk/clerk-react';
import { Bar } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    ArcElement,
    Title,
    Tooltip,
    Legend,
    CategoryScale,
    LinearScale,
    PointElement,
    BarElement,
} from 'chart.js';
import { Flex, Box, Table } from '@radix-ui/themes';

ChartJS.register(
    ArcElement,
    Title,
    Tooltip,
    Legend,
    CategoryScale,
    LinearScale,
    PointElement,
    BarElement
);

function DebtsEnergyAdviceWarmth({ customerData }) {
    const { isLoaded, isSignedIn } = useAuth();

    // debt_assistance_helping_agency
    // debt_assistance_helping_agency_comments
    // debt_assistance_helping_appointment
    // debt_assistance_helping_appointment_comments

    const workOutstandingAssists = ['Yes', 'No'];
    const workBenefitsAssists = ['Yes', 'No'];
    const workHelpAssists = ['Yes', 'No'];
    const workAssistance = [
        'Given helping agency details',
        'Made an appointment for client at helping agency',
    ];

    const [workOutstandingList, setWorkOutstandingList] = useState([]);
    const [workOutstandingChartData, setWorkOutstandingChartData] = useState();
    const [workBenefitsList, setWorkBenefitsList] = useState([]);
    const [workBenefitsChartData, setWorkBenefitsChartData] = useState();
    const [workBenefitsOtherList, setWorkBenefitsOtherList] = useState([]);
    const [workHelpList, setWorkHelpList] = useState([]);
    const [workHelpChartData, setWorkHelpChartData] = useState();
    const [workAssistanceList, setWorkAssistanceList] = useState([]);
    const [workAssistanceChartData, setWorkAssistanceChartData] = useState();

    useEffect(() => {
        if (isLoaded && isSignedIn) {
            // Outstanding debt that you may not be able to pay off?
            const workOutstandingList = workOutstandingAssists.map((a) => {
                return { count: 0, percent: 0, sum: 0, label: a };
            });
            customerData?.data?.map((customer) => {
                const label = customer.debt_outstanding?.data?.[0] === 1 ? 'Yes' : 'No';
                const exists = workOutstandingList.findIndex((b) => b.label === label);
                if (exists > -1) {
                    workOutstandingList[exists].count++;
                }
            });

            const sum = workOutstandingList.reduce((acc, o) => acc + parseInt(o.count), 0);

            workOutstandingList.forEach((b) => {
                b.percent = b.count === 0 ? 0 : Math.ceil((b.count / sum) * 100);
                b.sum = sum;
            });

            setWorkOutstandingList(workOutstandingList);

            // Claiming benefits?
            const workBenefitsList = workBenefitsAssists.map((a) => {
                return { count: 0, percent: 0, sum: 0, label: a };
            });
            customerData?.data?.map((customer) => {
                const label = customer.debt_benefits?.data?.[0] === 1 ? 'Yes' : 'No';
                const exists = workBenefitsList.findIndex((b) => b.label === label);
                if (exists > -1) {
                    workBenefitsList[exists].count++;
                }
            });

            const sumb = workBenefitsList.reduce((acc, o) => acc + parseInt(o.count), 0);

            workBenefitsList.forEach((b) => {
                b.percent = b.count === 0 ? 0 : Math.ceil((b.count / sumb) * 100);
                b.sum = sumb;
            });

            setWorkBenefitsList(workBenefitsList);

            // Accessed any help with money management/debt/benefits?
            const workHelpList = workHelpAssists.map((a) => {
                return { count: 0, percent: 0, sum: 0, label: a };
            });
            customerData?.data?.map((customer) => {
                const label = customer.debt_help?.data?.[0] === 1 ? 'Yes' : 'No';
                const exists = workHelpList.findIndex((b) => b.label === label);
                if (exists > -1) {
                    workHelpList[exists].count++;
                }
            });

            const sumh = workHelpList.reduce((acc, o) => acc + parseInt(o.count), 0);

            workHelpList.forEach((b) => {
                b.percent = b.count === 0 ? 0 : Math.ceil((b.count / sumh) * 100);
                b.sum = sumh;
            });

            setWorkHelpList(workHelpList);

            // Other Assistance given to service user
            const workAssistanceList = workAssistance.map((a) => {
                return { count: 0, percent: 0, sum: 0, label: a };
            });
            customerData?.data?.map((customer) => {
                workAssistance.map((w) => {
                    if (
                        (w === 'Given helping agency details' &&
                            customer.debt_assistance_helping_agency?.data?.[0] === 1) ||
                        (w === 'Made an appointment for client at helping agency' &&
                            customer.debt_assistance_helping_appointment?.data?.[0] === 1)
                    ) {
                        const exists = workAssistanceList.findIndex((b) => b.label === w);
                        if (exists > -1) {
                            workAssistanceList[exists].count++;
                        }
                    }
                });
            });
            const suma = workAssistanceList.reduce((acc, o) => acc + parseInt(o.count), 0);

            workAssistanceList.forEach((b) => {
                b.percent = b.count === 0 ? 0 : Math.ceil((b.count / suma) * 100);
                b.sum = suma;
            });

            setWorkAssistanceList(workAssistanceList);
        }
    }, [customerData, isLoaded, isSignedIn]);

    useEffect(() => {
        setWorkOutstandingChartData({
            labels: workOutstandingList.map((a) =>
                a?.label?.length > 0 ? a.label : 'Not answered'
            ),
            datasets: [
                {
                    label: 'Data',
                    data: workOutstandingList.map((a) => a.count),
                    backgroundColor: '#7ec7eb',
                },
            ],
        });
    }, [workOutstandingList]);

    useEffect(() => {
        setWorkBenefitsChartData({
            labels: workBenefitsList.map((a) => (a?.label?.length > 0 ? a.label : 'Not answered')),
            datasets: [
                {
                    label: 'Data',
                    data: workBenefitsList.map((a) => a.count),
                    backgroundColor: '#7ec7eb',
                },
            ],
        });
    }, [workBenefitsList]);

    useEffect(() => {
        setWorkHelpChartData({
            labels: workHelpList.map((a) => (a?.label?.length > 0 ? a.label : 'Not answered')),
            datasets: [
                {
                    label: 'Data',
                    data: workHelpList.map((a) => a.count),
                    backgroundColor: '#7eeb9b',
                },
            ],
        });
    }, [workHelpList]);

    useEffect(() => {
        setWorkAssistanceChartData({
            labels: workAssistanceList.map((a) =>
                a?.label?.length > 0 ? a.label : 'Not answered'
            ),
            datasets: [
                {
                    label: 'Data',
                    data: workAssistanceList.map((a) => a.count),
                    backgroundColor: '#7eeb9b',
                },
            ],
        });
    }, [workAssistanceList]);

    const workOutstandingOpts = {
        indexAxis: 'y',
        plugins: {
            legend: {
                display: false,
                position: 'top',
            },
            title: {
                display: true,
                text: 'Outstanding debt that you may not be able to pay off?',
                color: 'black',
                font: {
                    family: 'Open Sans,Helvetica,Arial,sans-serif',
                    weight: '400',
                    size: 18,
                    lineHeight: 1.42857,
                },
            },
        },
    };

    const workBenefitsOpts = {
        indexAxis: 'y',
        plugins: {
            legend: {
                display: false,
                position: 'top',
            },
            title: {
                display: true,
                text: 'Claiming benefits?',
                color: 'black',
                font: {
                    family: 'Open Sans,Helvetica,Arial,sans-serif',
                    weight: '400',
                    size: 18,
                    lineHeight: 1.42857,
                },
            },
        },
    };

    const workHelpOpts = {
        indexAxis: 'y',
        plugins: {
            legend: {
                display: false,
                position: 'top',
            },
            title: {
                display: true,
                text: 'Accessed any help with money management/debt/benefits?',
                color: 'black',
                font: {
                    family: 'Open Sans,Helvetica,Arial,sans-serif',
                    weight: '400',
                    size: 18,
                    lineHeight: 1.42857,
                },
            },
        },
    };

    const workAssistanceOpts = {
        indexAxis: 'y',
        plugins: {
            legend: {
                display: false,
                position: 'top',
            },
            title: {
                display: true,
                text: 'Other Assistance given to service user',
                color: 'black',
                font: {
                    family: 'Open Sans,Helvetica,Arial,sans-serif',
                    weight: '400',
                    size: 18,
                    lineHeight: 1.42857,
                },
            },
        },
    };

    useEffect(() => {
        if (customerData?.data?.length > 0) {
            const comments = customerData.data
                .map((customer) => customer.debt_benefits_comments)
                .filter((customer) => customer?.length > 0);
            setWorkBenefitsOtherList(comments);
        }
    }, [customerData]);

    return (
        <>
            <Flex gap="3">
                <Box className="LeftColumn">
                    {workOutstandingList?.length > 0 && (
                        <Table.Root>
                            <Table.Body>
                                {workOutstandingList.map((a, idx) => (
                                    <Table.Row key={`report-key-${idx}`}>
                                        <Table.RowHeaderCell>
                                            {a?.label?.length > 0 ? a.label : 'Not answered'}
                                        </Table.RowHeaderCell>
                                        <Table.Cell>{a.count}</Table.Cell>
                                        <Table.Cell>{a.percent}%</Table.Cell>
                                    </Table.Row>
                                ))}
                                <Table.Row>
                                    <Table.RowHeaderCell>&nbsp;</Table.RowHeaderCell>
                                    <Table.Cell>{workOutstandingList?.[0]?.sum}</Table.Cell>
                                    <Table.Cell>100%</Table.Cell>
                                </Table.Row>
                            </Table.Body>
                        </Table.Root>
                    )}
                </Box>
                <Box className="RightColumn" style={{ width: '800px' }}>
                    {workOutstandingChartData && (
                        <Bar data={workOutstandingChartData} options={workOutstandingOpts} />
                    )}
                </Box>
            </Flex>
            <Flex gap="3">
                <Box className="LeftColumn">
                    {workBenefitsList?.length > 0 && (
                        <Table.Root>
                            <Table.Body>
                                {workBenefitsList.map((a, idx) => (
                                    <Table.Row key={`report-key-${idx}`}>
                                        <Table.RowHeaderCell>
                                            {a?.label?.length > 0 ? a.label : 'Not answered'}
                                        </Table.RowHeaderCell>
                                        <Table.Cell>{a.count}</Table.Cell>
                                        <Table.Cell>{a.percent}%</Table.Cell>
                                    </Table.Row>
                                ))}
                                <Table.Row>
                                    <Table.RowHeaderCell>&nbsp;</Table.RowHeaderCell>
                                    <Table.Cell>{workBenefitsList?.[0]?.sum}</Table.Cell>
                                    <Table.Cell>100%</Table.Cell>
                                </Table.Row>
                            </Table.Body>
                        </Table.Root>
                    )}
                </Box>
                <Box className="LeftColumn">
                    {workBenefitsOtherList?.length > 0 && (
                        <Table.Root>
                            <Table.Body>
                                <Table.Row>
                                    <Table.RowHeaderCell>Comments</Table.RowHeaderCell>
                                </Table.Row>
                                {workBenefitsOtherList.map((a, idx) => (
                                    <Table.Row key={`report-comments-${idx}`}>
                                        <Table.Cell>{a}</Table.Cell>
                                    </Table.Row>
                                ))}
                            </Table.Body>
                        </Table.Root>
                    )}
                </Box>
                <Box className="RightColumn" style={{ width: '700px' }}>
                    {workBenefitsChartData && (
                        <Bar data={workBenefitsChartData} options={workBenefitsOpts} />
                    )}
                </Box>
            </Flex>
            <Flex gap="3">
                <Box className="LeftColumn">
                    {workHelpList?.length > 0 && (
                        <Table.Root>
                            <Table.Body>
                                {workHelpList.map((a, idx) => (
                                    <Table.Row key={`report-key-${idx}`}>
                                        <Table.RowHeaderCell>
                                            {a?.label?.length > 0 ? a.label : 'Not answered'}
                                        </Table.RowHeaderCell>
                                        <Table.Cell>{a.count}</Table.Cell>
                                        <Table.Cell>{a.percent}%</Table.Cell>
                                    </Table.Row>
                                ))}
                                <Table.Row>
                                    <Table.RowHeaderCell>&nbsp;</Table.RowHeaderCell>
                                    <Table.Cell>{workHelpList?.[0]?.sum}</Table.Cell>
                                    <Table.Cell>100%</Table.Cell>
                                </Table.Row>
                            </Table.Body>
                        </Table.Root>
                    )}
                </Box>
                <Box className="RightColumn" style={{ width: '800px' }}>
                    {workHelpChartData && <Bar data={workHelpChartData} options={workHelpOpts} />}
                </Box>
            </Flex>

            <Flex gap="3">
                <Box className="LeftColumn">
                    {workAssistanceList?.length > 0 && (
                        <Table.Root>
                            <Table.Body>
                                {workAssistanceList.map((a, idx) => (
                                    <Table.Row key={`report-key-${idx}`}>
                                        <Table.RowHeaderCell>
                                            {a?.label?.length > 0 ? a.label : 'Not answered'}
                                        </Table.RowHeaderCell>
                                        <Table.Cell>{a.count}</Table.Cell>
                                        <Table.Cell>{a.percent}%</Table.Cell>
                                    </Table.Row>
                                ))}
                                <Table.Row>
                                    <Table.RowHeaderCell>&nbsp;</Table.RowHeaderCell>
                                    <Table.Cell>{workAssistanceList?.[0]?.sum}</Table.Cell>
                                    <Table.Cell>100%</Table.Cell>
                                </Table.Row>
                            </Table.Body>
                        </Table.Root>
                    )}
                </Box>
                <Box className="RightColumn" style={{ width: '800px' }}>
                    {workAssistanceChartData && (
                        <Bar data={workAssistanceChartData} options={workAssistanceOpts} />
                    )}
                </Box>
            </Flex>
        </>
    );
}

export default DebtsEnergyAdviceWarmth;
