import { useEffect, useState } from 'react';
import { authenticatedFetch } from '../../helpers/fetch';
import Loading from 'react-fullscreen-loading';
import { useAuth } from '@clerk/clerk-react';
import { Bar } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    ArcElement,
    Title,
    Tooltip,
    Legend,
    CategoryScale,
    LinearScale,
    PointElement,
    BarElement,
} from 'chart.js';
import { Flex, Box, Table } from '@radix-ui/themes';

ChartJS.register(
    ArcElement,
    Title,
    Tooltip,
    Legend,
    CategoryScale,
    LinearScale,
    PointElement,
    BarElement
);

function WorriesAboutBills({ customerData }) {
    const { getToken, isLoaded, isSignedIn } = useAuth();

    const [billData, setBillData] = useState({ data: [], isLoading: false });
    const [customerBillData, setCustomerBillData] = useState({ data: [], isLoading: false });
    const [billList, setBillList] = useState([]);
    const [otherList, setOtherList] = useState([]);
    const [billChartData, setBillChartData] = useState();

    useEffect(() => {
        const load = async () => {
            if (isLoaded && isSignedIn) {
                setBillData({ data: [], isLoading: true });
                authenticatedFetch(`${window.location.origin}/api/lookup/bill`, await getToken())
                    .then((res) => res.json())
                    .then((data) => {
                        setBillData({ data: data, isLoading: false });
                    });
            }
        };
        load();
    }, [isLoaded, isSignedIn]);

    useEffect(() => {
        const load = async () => {
            if (isLoaded && isSignedIn) {
                setCustomerBillData({ data: [], isLoading: true });
                authenticatedFetch(
                    `${window.location.origin}/api/data/customer/0/bills`,
                    await getToken()
                )
                    .then((res) => res.json())
                    .then((data) => {
                        setCustomerBillData({ data: data, isLoading: false });
                    });
            }
        };
        load();
    }, [isLoaded, isSignedIn]);

    useEffect(() => {
        setBillChartData({
            labels: billList.map((a) => a.label),
            datasets: [
                {
                    label: 'Data',
                    data: billList.map((a) => a.count),
                    backgroundColor: '#7ec7eb',
                },
            ],
        });
    }, [billList]);

    const billOpts = {
        indexAxis: 'y',
        plugins: {
            legend: {
                display: false,
                position: 'top',
            },
            title: {
                display: true,
                text: 'Worried about paying rent/energy bills, food or other essentials?',
                color: 'black',
                font: {
                    family: 'Open Sans,Helvetica,Arial,sans-serif',
                    weight: '400',
                    size: 18,
                    lineHeight: 1.42857,
                },
            },
        },
    };

    useEffect(() => {
        if (
            billData?.data?.length > 0 &&
            customerBillData?.data?.length > 0 &&
            customerData?.data?.length > 0
        ) {
            const customerBillDataFiltered = customerBillData?.data?.filter((c) =>
                customerData?.data?.map((c) => c.id).includes(c.customer_id)
            );

            const billList = billData?.data?.map((a) => {
                return { count: 0, percent: 0, sum: 0, id: a.id, label: a.name };
            });
            customerBillDataFiltered?.map((custBill) => {
                const exists = billList.findIndex((b) => custBill.id === b.id);
                if (exists > -1) {
                    billList[exists].count++;
                }
            });

            const sum = billList.reduce((acc, o) => acc + parseInt(o.count), 0);

            billList.forEach((b) => {
                b.percent = b.count === 0 ? 0 : Math.ceil((b.count / sum) * 100);
                b.sum = sum;
            });

            setBillList(billList);
        }
    }, [billData, customerBillData]);

    useEffect(() => {
        if (customerData?.data?.length > 0) {
            const other = customerData.data
                .map((customer) => customer.bill_other)
                .filter((customer) => customer?.length > 0);
            setOtherList(other);
        }
    }, [customerData]);

    if (billData.isLoading || customerBillData.isLoading)
        return <Loading loading loaderColor="#3498db" />;

    return (
        <>
            <Flex gap="3">
                <Box className="LeftColumn">
                    {billList?.length > 0 && (
                        <Table.Root>
                            <Table.Body>
                                {billList.map((a, idx) => (
                                    <Table.Row key={`report-key-${idx}`}>
                                        <Table.RowHeaderCell>{a.label}</Table.RowHeaderCell>
                                        <Table.Cell>{a.count}</Table.Cell>
                                        <Table.Cell>{a.percent}%</Table.Cell>
                                    </Table.Row>
                                ))}
                                <Table.Row>
                                    <Table.RowHeaderCell>&nbsp;</Table.RowHeaderCell>
                                    <Table.Cell>{billList?.[0]?.sum}</Table.Cell>
                                    <Table.Cell>100%</Table.Cell>
                                </Table.Row>
                            </Table.Body>
                        </Table.Root>
                    )}
                </Box>
                <Box className="RightColumn" style={{ width: '800px' }}>
                    {billChartData && <Bar data={billChartData} options={billOpts} />}
                </Box>
            </Flex>
            <Flex gap="3">
                <Box className="LeftColumn">
                    {otherList?.length > 0 && (
                        <Table.Root>
                            <Table.Body>
                                <Table.Row>
                                    <Table.RowHeaderCell>Other reasons</Table.RowHeaderCell>
                                </Table.Row>
                                {otherList.map((a, idx) => (
                                    <Table.Row key={`report-other-${idx}`}>
                                        <Table.Cell>{a}</Table.Cell>
                                    </Table.Row>
                                ))}
                            </Table.Body>
                        </Table.Root>
                    )}
                </Box>
            </Flex>
        </>
    );
}

export default WorriesAboutBills;
