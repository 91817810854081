import { useEffect, useState } from 'react';
import { useAuth } from '@clerk/clerk-react';
import { authenticatedFetch } from '../helpers/fetch';
import '../styles/Footer.css';

function Footer() {
    const { getToken, isLoaded, isSignedIn } = useAuth();

    const [versionData, setVersionData] = useState({ data: [], isLoading: false });

    useEffect(() => {
        const loadVersionNumber = async () => {
            if (isLoaded && isSignedIn) {
                setVersionData({ data: [], isLoading: true });
                authenticatedFetch(`${window.location.origin}/version.json`, await getToken())
                    .then((res) => res.json())
                    .then((data) => {
                        setVersionData({ data: data, isLoading: false });
                    });
            }
        };

        loadVersionNumber();
    }, [isLoaded, isSignedIn]);

    return (
        <footer>
            <strong>v{versionData?.data?.version}</strong>
        </footer>
    );
}

export default Footer;
