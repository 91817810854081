import React from 'react';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { Table, Text } from '@radix-ui/themes';
import classNames from 'classnames';
import '../styles/PopupMenu.css';

const PopupMenu = ({
    onClick,
    dateStatusData,
    attendanceDate,
    classes,
    key,
    style,
    isMonth,
    funding,
}) => {
    if (isMonth) {
        return (
            <DropdownMenu.Root>
                <DropdownMenu.Trigger asChild>
                    <div key={key} style={style} className={classes}>
                        {attendanceDate?.datestatus_name && (
                            <Text color="gray" size="1">
                                {attendanceDate?.datestatus_name}
                            </Text>
                        )}
                    </div>
                </DropdownMenu.Trigger>

                <DropdownMenu.Portal>
                    <DropdownMenu.Content className="DropdownMenuContent" sideOffset={5}>
                        {funding && (
                            <DropdownMenu.Item className="DropdownMenuItem DropdownMenuItemFunding">
                                {funding} funding
                            </DropdownMenu.Item>
                        )}
                        {dateStatusData?.data?.map((status, idx) => {
                            const cls = classNames({
                                DropdownMenuItem: true,
                                DropdownMenuItemOn: attendanceDate?.datestatus_name == status.name,
                            });

                            return (
                                <DropdownMenu.Item
                                    className={cls}
                                    key={`customer-attendance-status-${idx}`}
                                    onSelect={() => onClick(status.id, status.name)}
                                >
                                    {status.name}
                                </DropdownMenu.Item>
                            );
                        })}
                    </DropdownMenu.Content>
                </DropdownMenu.Portal>
            </DropdownMenu.Root>
        );
    }

    if (!isMonth) {
        return (
            <DropdownMenu.Root>
                <DropdownMenu.Trigger asChild>
                    <Table.Cell className={classes} key={`customer-weekday-key`}>
                        {attendanceDate?.datestatus_name && (
                            <Text color="gray" size="1">
                                {attendanceDate?.datestatus_name}
                            </Text>
                        )}
                    </Table.Cell>
                </DropdownMenu.Trigger>

                <DropdownMenu.Portal>
                    <DropdownMenu.Content className="DropdownMenuContent" sideOffset={5}>
                        {funding && (
                            <DropdownMenu.Item className="DropdownMenuItem DropdownMenuItemFunding">
                                {funding} funding
                            </DropdownMenu.Item>
                        )}
                        {dateStatusData?.data?.map((status, idx) => {
                            const cls = classNames({
                                DropdownMenuItem: true,
                                DropdownMenuItemOn: attendanceDate?.datestatus_name == status.name,
                            });

                            return (
                                <DropdownMenu.Item
                                    className={cls}
                                    key={`customer-attendance-status-${idx}`}
                                    onSelect={() => onClick(status.id, status.name)}
                                >
                                    {status.name}
                                </DropdownMenu.Item>
                            );
                        })}
                    </DropdownMenu.Content>
                </DropdownMenu.Portal>
            </DropdownMenu.Root>
        );
    }
};

export default PopupMenu;
