import React, { useEffect, useState } from 'react';
import { authenticatedFetch } from './helpers/fetch';
import { useNavigate, useParams } from 'react-router-dom';
import { SignedIn, useUser, useAuth } from '@clerk/clerk-react';
import {
    Heading,
    Button,
    Text,
    TextField,
    TextArea,
    Flex,
    Separator,
    Strong,
    Checkbox,
    Callout,
} from '@radix-ui/themes';
import { MinusIcon, PlusIcon, InfoCircledIcon } from '@radix-ui/react-icons';
import SignedOutLink from './components/SignedOut';
import ThreeStateCheckbox from './components/ThreeStateCheckbox';
import Breadcrumb from './components/Breadcrumb';
import SelectElement from './components/SelectElement';
import SelectRange from './components/SelectRange';
import CheckboxList from './components/CheckboxList';
import ColourCard from './components/ColourCard';
import Calendar from './components/Calendar';
import Error from './components/Error';
import { useForm } from 'react-hook-form';
import * as Form from '@radix-ui/react-form';
import { addDays, isBefore, nextDay } from 'date-fns';
import {
    months,
    weekdays,
    sortByFirstNumber,
    addCalendarDate,
    loadCustomer,
    watchFieldsOnLoadCustomer,
    getWeekDayNumber,
    addUpdateCustomer,
} from './helpers/data.js';
import Loading from 'react-fullscreen-loading';
import './styles/Customers.css';
import './styles/Forms.css';

function CustomersForm() {
    let navigate = useNavigate();
    const params = useParams();

    const { user } = useUser();
    const { getToken, isLoaded, isSignedIn } = useAuth();

    const [title, setTitle] = React.useState('Add New Customer');

    const [countryData, setCountryData] = useState({ data: [], isLoading: false });
    const [maritalData, setMaritalData] = useState({ data: [], isLoading: false });
    const [serviceData, setServiceData] = useState({ data: [], isLoading: false });
    const [adultAgeData, setAdultAgeData] = useState({ data: [], isLoading: false });
    const [childAgeData, setChildAgeData] = useState({ data: [], isLoading: false });
    const [housingData, setHousingData] = useState({ data: [], isLoading: false });
    const [employmentData, setEmploymentData] = useState({ data: [], isLoading: false });
    const [fundingData, setFundingData] = useState({ data: [], isLoading: false });
    const [healthData, setHealthData] = useState({ data: [], isLoading: false });
    const [energyTryData, setEnergyTryData] = useState({ data: [], isLoading: false });
    const [billData, setBillData] = useState({ data: [], isLoading: false });

    const [formErrors, setFormErrors] = React.useState({ keys: [], val: '' });

    const { register, handleSubmit, setValue, getValues, watch } = useForm();

    const watchAllFields = watch();

    /** Lookup data */
    useEffect(() => {
        const load = async () => {
            if (isLoaded && isSignedIn) {
                setCountryData({ data: [], isLoading: true });
                authenticatedFetch(`${window.location.origin}/api/lookup/country`, await getToken())
                    .then((res) => res.json())
                    .then((data) => {
                        setCountryData({ data: data, isLoading: false });
                    });
            }
        };
        load();
    }, [isLoaded, isSignedIn]);

    useEffect(() => {
        const load = async () => {
            if (isLoaded && isSignedIn) {
                setMaritalData({ data: [], isLoading: true });
                authenticatedFetch(`${window.location.origin}/api/lookup/marital`, await getToken())
                    .then((res) => res.json())
                    .then((data) => {
                        setMaritalData({ data: data, isLoading: false });
                    });
            }
        };
        load();
    }, [isLoaded, isSignedIn]);

    useEffect(() => {
        const load = async () => {
            if (isLoaded && isSignedIn) {
                setServiceData({ data: [], isLoading: true });
                authenticatedFetch(`${window.location.origin}/api/lookup/service`, await getToken())
                    .then((res) => res.json())
                    .then((data) => {
                        setServiceData({ data: data, isLoading: false });
                    });
            }
        };
        load();
    }, [isLoaded, isSignedIn]);

    useEffect(() => {
        const load = async () => {
            if (isLoaded && isSignedIn) {
                setAdultAgeData({ data: [], isLoading: true });
                authenticatedFetch(
                    `${window.location.origin}/api/lookup/adultAge`,
                    await getToken()
                )
                    .then((res) => res.json())
                    .then((data) => {
                        setAdultAgeData({ data: data, isLoading: false });
                    });
            }
        };
        load();
    }, [isLoaded, isSignedIn]);

    useEffect(() => {
        const load = async () => {
            if (isLoaded && isSignedIn) {
                setChildAgeData({ data: [], isLoading: true });
                authenticatedFetch(
                    `${window.location.origin}/api/lookup/childAge`,
                    await getToken()
                )
                    .then((res) => res.json())
                    .then((data) => {
                        setChildAgeData({ data: data, isLoading: false });
                    });
            }
        };
        load();
    }, [isLoaded, isSignedIn]);

    useEffect(() => {
        const load = async () => {
            if (isLoaded && isSignedIn) {
                setHousingData({ data: [], isLoading: true });
                authenticatedFetch(`${window.location.origin}/api/lookup/housing`, await getToken())
                    .then((res) => res.json())
                    .then((data) => {
                        setHousingData({ data: data, isLoading: false });
                    });
            }
        };
        load();
    }, [isLoaded, isSignedIn]);

    useEffect(() => {
        const load = async () => {
            if (isLoaded && isSignedIn) {
                setEmploymentData({ data: [], isLoading: true });
                authenticatedFetch(
                    `${window.location.origin}/api/lookup/employment`,
                    await getToken()
                )
                    .then((res) => res.json())
                    .then((data) => {
                        setEmploymentData({ data: data, isLoading: false });
                    });
            }
        };
        load();
    }, [isLoaded, isSignedIn]);

    useEffect(() => {
        const load = async () => {
            if (isLoaded && isSignedIn) {
                setFundingData({ data: [], isLoading: true });
                authenticatedFetch(`${window.location.origin}/api/lookup/funding`, await getToken())
                    .then((res) => res.json())
                    .then((data) => {
                        setFundingData({ data: data, isLoading: false });
                    });
            }
        };
        load();
    }, [isLoaded, isSignedIn]);

    useEffect(() => {
        const load = async () => {
            if (isLoaded && isSignedIn) {
                setHealthData({ data: [], isLoading: true });
                authenticatedFetch(`${window.location.origin}/api/lookup/health`, await getToken())
                    .then((res) => res.json())
                    .then((data) => {
                        setHealthData({ data: data, isLoading: false });
                    });
            }
        };
        load();
    }, [isLoaded, isSignedIn]);

    useEffect(() => {
        const load = async () => {
            if (isLoaded && isSignedIn) {
                setEnergyTryData({ data: [], isLoading: true });
                authenticatedFetch(
                    `${window.location.origin}/api/lookup/energyTry`,
                    await getToken()
                )
                    .then((res) => res.json())
                    .then((data) => {
                        setEnergyTryData({ data: data, isLoading: false });
                    });
            }
        };
        load();
    }, [isLoaded, isSignedIn]);

    useEffect(() => {
        const load = async () => {
            if (isLoaded && isSignedIn) {
                setBillData({ data: [], isLoading: true });
                authenticatedFetch(`${window.location.origin}/api/lookup/bill`, await getToken())
                    .then((res) => res.json())
                    .then((data) => {
                        setBillData({ data: data, isLoading: false });
                    });
            }
        };
        load();
    }, [isLoaded, isSignedIn]);

    /** Loading data to populate form */
    const [populatingForm, setPopulatingForm] = React.useState({});

    /** Dropdowns/Custom fieldtypes */
    const [dobDay, setDobDay] = React.useState(null);
    const [dobMonth, setDobMonth] = React.useState(null);
    const [dobYear, setDobYear] = React.useState(null);
    const [gender, setGender] = React.useState();
    const [country, setCountry] = React.useState();
    const [marital, setMarital] = React.useState();
    const [service, setService] = React.useState();
    const [showServiceOther, setShowServiceOther] = React.useState(false);
    const [showServicePartnershipOther, setShowServicePartnershipOther] = React.useState(false);

    const [membershipDay, setMembershipDay] = React.useState(null);
    const [membershipMonth, setMembershipMonth] = React.useState(null);
    const [membershipYear, setMembershipYear] = React.useState(null);

    const [membershipEndDay, setMembershipEndDay] = React.useState(null);
    const [membershipEndMonth, setMembershipEndMonth] = React.useState(null);
    const [membershipEndYear, setMembershipEndYear] = React.useState(null);

    const [adultsHousehold, setAdultsHousehold] = React.useState(0);
    const [childrenHousehold, setChildrenHousehold] = React.useState(0);
    const [adultAge, setAdultAge] = React.useState();
    const [childAge, setChildAge] = React.useState();
    const [housing, setHousing] = React.useState();
    const [showHousingOther, setShowHousingOther] = React.useState(false);
    const [employment, setEmployment] = React.useState();
    const [showEmploymentOther, setShowEmploymentOther] = React.useState(false);
    const [workRelatedHelp, setWorkRelatedHelp] = React.useState();
    const [showWorkRelatedHelpOther, setShowWorkRelatedHelpOther] = React.useState(false);
    const [funding, setFunding] = React.useState();
    const [health, setHealth] = React.useState();
    const [showHealthOther, setShowHealthOther] = React.useState(false);
    const [energyTry, setEnergyTry] = React.useState();
    const [showEnergyTryOther, setShowEnergyTryOther] = React.useState(false);
    const [bill, setBill] = React.useState();
    const [skills, setSkills] = React.useState();
    const [showBillOther, setShowBillOther] = React.useState(false);
    const [showDebtBenefitsCommentsOther, setShowDebtBenefitsCommentsOther] = React.useState(false);
    const [debtAssistance, setDebtAssistance] = React.useState();
    const [
        showDebtAssistanceHelpingAgencyCommentsOther,
        setShowDebtAssistanceHelpingAgencyCommentsOther,
    ] = React.useState(false);
    const [
        showDebtAssistanceHelpingAppointmentCommentsOther,
        setShowDebtAssistanceHelpingAppointmentCommentsOther,
    ] = React.useState(false);

    const [selectedServices, setSelectedServices] = React.useState(['None']);
    const [selectedCalendarDates, setSelectedCalendarDates] = React.useState([]);

    const [energyKeepingWarm, setEnergyKeepingWarm] = React.useState('False');
    const [energyAccessedInfo, setEnergyAccessedInfo] = React.useState('False');
    const [debtOutstanding, setDebtOutstanding] = React.useState('False');
    const [debtBenefits, setDebtBenefits] = React.useState(false);
    const [debtHelp, setDebtHelp] = React.useState(false);

    const [paymentType, setPaymentType] = React.useState();

    const [dateService, setDateService] = React.useState();
    const [serviceWeekday, setServiceWeekday] = React.useState('');
    const [serviceFunding, setServiceFunding] = React.useState('');

    const [enabled, setEnabled] = React.useState(false);

    const [saving, setSaving] = React.useState(false);
    const [savingError, setSavingError] = React.useState();

    const [loading, setLoading] = React.useState(false);
    const [loadingError, setLoadingError] = React.useState();

    useEffect(() => {
        setLoading(true);
    }, [params]);

    useEffect(() => {
        if (loading)
            loadCustomer(params, setTitle, setLoadingError, setValue, setLoading, getToken);
    }, [loading]);

    useEffect(() => {
        watchFieldsOnLoadCustomer(
            watchAllFields,
            populatingForm,
            setPopulatingForm,
            setValue,
            {
                setDobDay,
                setDobMonth,
                setDobYear,
                setGender,
                setCountry,
                setMarital,
                setService,
                setSelectedCalendarDates,
                setMembershipDay,
                setMembershipMonth,
                setMembershipYear,
                setMembershipEndDay,
                setMembershipEndMonth,
                setMembershipEndYear,
                setAdultsHousehold,
                setChildrenHousehold,
                setHousing,
                setEmployment,
                setSkills,
                setWorkRelatedHelp,
                setFunding,
                setHealth,
                setEnergyKeepingWarm,
                setEnergyAccessedInfo,
                setEnergyTry,
                setBill,
                setDebtOutstanding,
                setDebtBenefits,
                setShowDebtBenefitsCommentsOther,
                setDebtHelp,
                setDebtAssistance,
                setAdultAge,
                setChildAge,
                setPaymentType,
                setEnabled,
            },
            { countryData, maritalData, housingData, employmentData, fundingData }
        );
    }, [watchAllFields, countryData, maritalData, housingData, employmentData, fundingData]);

    useEffect(() => {
        setValue('dobHidden', `${dobDay}-${dobMonth}-${dobYear}`);
        setValue('genderHidden', gender);
        setValue('countryHidden', country);
        setValue('serviceHidden', service);
        setValue('membershipHidden', `${membershipDay}-${membershipMonth}-${membershipYear}`);
        setValue(
            'membershipEndHidden',
            `${membershipEndDay}-${membershipEndMonth}-${membershipEndYear}`
        );
        setValue('adults_householdHidden', adultsHousehold);
        setValue('children_householdHidden', childrenHousehold);
        setValue('adultsAgeHidden', adultAge);
        setValue('childAgeHidden', childAge);
        setValue('housingHidden', housing);
        setValue('maritalHidden', marital);
        setValue('employmentHidden', employment);
        setValue('workRelatedHelpHidden', workRelatedHelp);
        setValue('fundingHidden', funding);
        setValue('healthHidden', health);
        setValue('energyTryHidden', energyTry);
        setValue('billHidden', bill);
        setValue('skillsHidden', skills);
        setValue('debtAssistanceHidden', debtAssistance);
    }, [
        dobDay,
        dobMonth,
        dobYear,
        gender,
        country,
        membershipDay,
        membershipMonth,
        membershipYear,
        membershipEndDay,
        membershipEndMonth,
        membershipEndYear,
        adultsHousehold,
        childrenHousehold,
        adultAge,
        childAge,
        housing,
        service,
        marital,
        employment,
        workRelatedHelp,
        funding,
        health,
        energyTry,
        bill,
        skills,
        debtAssistance,
    ]);

    useEffect(() => {
        setShowHousingOther(housing === 'Other' ? true : false);
    }, [housing]);

    useEffect(() => {
        setShowServiceOther(service?.split('-').includes('Other') ? true : false);
        setShowServicePartnershipOther(
            service?.split('-').includes('Professional worker/partnership working') ? true : false
        );
        setSelectedServices(service?.length === 0 ? ['None'] : service?.split('-'));
    }, [service]);

    useEffect(() => {
        setShowEmploymentOther(employment === 'Other' ? true : false);
    }, [employment]);

    useEffect(() => {
        setShowWorkRelatedHelpOther(workRelatedHelp === 'Yes' ? true : false);
    }, [workRelatedHelp]);

    useEffect(() => {
        setShowHealthOther(health?.split('-').includes('Other') ? true : false);
    }, [health]);

    useEffect(() => {
        setShowEnergyTryOther(energyTry?.split('-').includes('Other') ? true : false);
    }, [energyTry]);

    useEffect(() => {
        setShowBillOther(bill?.split('-').includes('Yes, Other') ? true : false);
    }, [bill]);

    useEffect(() => {
        setShowDebtAssistanceHelpingAgencyCommentsOther(
            debtAssistance?.split('-').includes('Given helping agency details') ? true : false
        );
        setShowDebtAssistanceHelpingAppointmentCommentsOther(
            debtAssistance?.split('-').includes('Made an appointment for client at helping agency')
                ? true
                : false
        );
    }, [debtAssistance]);

    useEffect(() => {
        setValue('calendarHidden', JSON.stringify(selectedCalendarDates));
    }, [selectedCalendarDates]);

    useEffect(() => {
        const s = async () => {
            if (saving === true) {
                const { formErrs, err, json } = await addUpdateCustomer(
                    params,
                    getValues(),
                    user,
                    getToken,
                    {
                        countryData,
                        maritalData,
                        housingData,
                        employmentData,
                        fundingData,
                        billData,
                        serviceData,
                        energyTryData,
                        healthData,
                        adultAgeData,
                        childAgeData,
                    }
                );
                if (formErrs?.keys?.length > 0) {
                    setFormErrors(formErrs);
                    setSavingError();
                    setSaving(false);
                    document.documentElement.scrollTop = 0;
                } else if (err) {
                    setSavingError(err);
                    setSaving(false);
                } else {
                    navigate(`/customers?success=${json.id}`);
                    setSaving(false);
                }
            }
        };
        s();
    }, [saving]);

    const onGenderChange = (val) => {
        setGender(val);
    };

    const onCountryChange = (val) => {
        setCountry(val);
    };

    const onServiceChange = (val) => {
        setService(val);
    };

    const onServiceWeekdayAddDel = (e, action) => {
        e.preventDefault();

        if (
            !(serviceWeekday?.length > 0 && serviceFunding?.length > 0 && dateService?.length > 0)
        ) {
            return;
        }

        const start = nextDay(new Date(), getWeekDayNumber(serviceWeekday)),
            end = new Date(2025, 0, 1);
        const dates = [];

        let current = start;
        while (isBefore(current, end)) {
            dates.push(current);
            current = addDays(current, 7);
        }

        let cloned = [...selectedCalendarDates];
        cloned = addCalendarDate(cloned, action, dates, dateService, serviceFunding);

        setSelectedCalendarDates(cloned);
    };

    const onDateServicesChange = (val) => {
        setDateService(val);
        setValue('date_servicesHidden', val);
    };

    const onDateWeekdayChange = (val) => {
        setServiceWeekday(val);
        setValue('date_weekdayHidden', val);
    };

    const onDateFundingChange = (val) => {
        setServiceFunding(val);
        setValue('date_fundingHidden', val);
    };

    const onAdultsHouseholdChange = (val) => {
        setAdultsHousehold(val);
    };

    const onChildrenHouseholdChange = (val) => {
        setChildrenHousehold(val);
    };

    const onAdultAgeChange = (val) => {
        setAdultAge(val);
    };

    const onChildAgeChange = (val) => {
        setChildAge(val);
    };

    const onHousingChange = (val) => {
        setHousing(val);
    };

    const onMaritalChange = (val) => {
        setMarital(val);
    };

    const onEmploymentChange = (val) => {
        setEmployment(val);
    };

    const onSkillsChange = (val) => {
        setSkills(val);
    };

    const onWorkRelatedHelpChange = (val) => {
        setWorkRelatedHelp(val);
    };

    const onFundingChange = (val) => {
        setFunding(val);
    };

    const onHealthChange = (val) => {
        setHealth(val);
    };

    const onEnabledChange = (val) => {
        setValue('enabledHidden', val === true ? 'true' : '');
        setEnabled(val === true);
    };

    const onEnergyKeepingWarmChange = (val) => {
        setValue('energyKeepingWarmHidden', val);
        setEnergyKeepingWarm(val);
    };

    const onEnergyAccessedInfoChange = (val) => {
        setValue('energyAccessedInfoHidden', val);
        setEnergyAccessedInfo(val);
    };

    const onEnergyTryChange = (val) => {
        setEnergyTry(val);
    };

    const onBillChange = (val) => {
        setBill(val);
    };

    const onDebtOutstandingChange = (val) => {
        setValue('debtOutstandingHidden', val);
        setDebtOutstanding(val);
    };

    const onDebtBenefitsChange = (val) => {
        setShowDebtBenefitsCommentsOther(val === true ? true : false);
        setValue('debtBenefitsHidden', val === true ? 'true' : '');
        setDebtBenefits(val === true);
    };

    const onDebtHelpChange = (val) => {
        setValue('debtHelpHidden', val === true ? 'true' : '');
        setDebtHelp(val === true);
    };

    const onCalendarChange = (name, val) => {
        let cloned = [...selectedCalendarDates];

        // find array of dates for this service, or create if doesn't exist
        cloned = addCalendarDate(cloned, 'individual', val, dateService, serviceFunding);

        setSelectedCalendarDates(cloned);
    };

    const onDebtAssistanceChange = (val) => {
        setDebtAssistance(val);
    };

    const onDOBChange = (val, name) => {
        if (name === 'dob-day') setDobDay(val);
        if (name === 'dob-month') setDobMonth(val);
        if (name === 'dob-year') setDobYear(val);
    };

    const onMembershipChange = (val, name) => {
        if (name === 'membership-day') setMembershipDay(val);
        if (name === 'membership-month') setMembershipMonth(val);
        if (name === 'membership-year') setMembershipYear(val);
        if (name === 'membership-end-day') setMembershipEndDay(val);
        if (name === 'membership-end-month') setMembershipEndMonth(val);
        if (name === 'membership-end-year') setMembershipEndYear(val);
    };

    const onPayerTypeChange = (val) => {
        setValue('payer_typeHidden', val);
        setPaymentType(val);
    };

    const isError = (name) => {
        return formErrors?.keys.includes(name) ? 'error' : '';
    };

    const onSubmit = async () => {
        setSaving(true);
    };

    if (
        countryData.isLoading ||
        adultAgeData.isLoading ||
        childAgeData.isLoading ||
        housingData.isLoading ||
        serviceData.isLoading ||
        maritalData.isLoading ||
        employmentData.isLoading ||
        fundingData.isLoading ||
        healthData.isLoading ||
        energyTryData.isLoading ||
        billData.isLoading ||
        saving ||
        loading
    )
        return <Loading loading loaderColor="#3498db" />;
    if (countryData.error)
        return <Error txt={`An error has occurred (countryData): ${countryData.error.message}`} />;
    if (adultAgeData.error)
        return (
            <Error txt={`An error has occurred (adultAgeData): ${adultAgeData.error.message}`} />
        );
    if (childAgeData.error)
        return (
            <Error txt={`An error has occurred (childAgeData): ${childAgeData.error.message}`} />
        );
    if (housingData.error)
        return <Error txt={`An error has occurred (housingData): ${housingData.error.message}`} />;
    if (serviceData.error)
        return <Error txt={`An error has occurred (serviceData): ${serviceData.error.message}`} />;
    if (maritalData.error)
        return <Error txt={`An error has occurred (maritalData): ${maritalData.error.message}`} />;
    if (employmentData.error)
        return (
            <Error
                txt={`An error has occurred (employmentData): ${employmentData.error.message}`}
            />
        );
    if (fundingData.error)
        return <Error txt={`An error has occurred (fundingData): ${fundingData.error.message}`} />;
    if (healthData.error)
        return <Error txt={`An error has occurred (healthData): ${healthData.error.message}`} />;
    if (energyTryData.error)
        return (
            <Error txt={`An error has occurred (energyTryData): ${energyTryData.error.message}`} />
        );
    if (billData.error)
        return <Error txt={`An error has occurred (billData): ${billData.error.message}`} />;
    if (savingError)
        return <Error txt={`An error has occurred (saving): ${savingError.toString()}`} />;
    if (loadingError)
        return <Error txt={`An error has occurred (loading): ${loadingError.toString()}`} />;

    const links = [
        { url: '/customers', text: 'Customers Data' },
        { url: '/customers/add', text: title },
    ];

    return (
        <>
            <SignedOutLink />
            <SignedIn>
                <div className="page-header">
                    <Heading>{title}</Heading>
                </div>
                <Breadcrumb links={links} />

                {formErrors?.val?.length > 0 && (
                    <Callout.Root color="red" className="notification-callout">
                        <Callout.Icon>
                            <InfoCircledIcon />
                        </Callout.Icon>
                        <Callout.Text>{formErrors.val}</Callout.Text>
                    </Callout.Root>
                )}

                <Form.Root className="FormRoot" onSubmit={handleSubmit(onSubmit)}>
                    <Strong className="FormSectionTitle">Basic Details</Strong>

                    <Flex className="FormColumn" direction="column" gap="3">
                        <Form.Field className="FormField" name="first_name">
                            <div className="FormLabelOuter">
                                <Form.Label className="FormLabel">First Name *</Form.Label>
                            </div>
                            <Form.Control asChild>
                                <TextField.Input
                                    className={`${isError('first_name')}`}
                                    name="first_name"
                                    {...register('first_name')}
                                    maxLength={128}
                                />
                            </Form.Control>
                        </Form.Field>
                    </Flex>

                    <Flex className="FormColumn" direction="column" gap="3">
                        <Form.Field className="FormField" name="last_name">
                            <div className="FormLabelOuter">
                                <Form.Label className="FormLabel">Last Name *</Form.Label>
                            </div>
                            <Form.Control asChild>
                                <TextField.Input
                                    className={`${isError('last_name')}`}
                                    name="last_name"
                                    {...register('last_name')}
                                    maxLength={128}
                                />
                            </Form.Control>
                        </Form.Field>
                    </Flex>

                    <Separator className="Empty" orientation="horizontal" size="4" />

                    <Flex className="FormColumn" direction="column" gap="3">
                        <Form.Field className="FormField" name="dobHidden">
                            <div className="FormLabelOuter">
                                <Form.Label className="FormLabel">Date of Birth</Form.Label>
                            </div>
                            <Form.Control asChild>
                                <input type="hidden" name="dobHidden" {...register('dobHidden')} />
                            </Form.Control>
                            <SelectElement
                                name="dob-day"
                                label="Day"
                                placeholder="Day"
                                data={Array.from(Array(31), (_, x) => x + 1)}
                                onChange={onDOBChange.bind(this)}
                                value={dobDay}
                            />
                            <SelectElement
                                name="dob-month"
                                label="Month"
                                placeholder="Month"
                                data={months}
                                onChange={onDOBChange.bind(this)}
                                value={dobMonth}
                            />
                            <SelectElement
                                name="dob-year"
                                label="Year"
                                placeholder="Year"
                                data={Array.from(
                                    Array(100),
                                    (_, x) => x + (new Date().getFullYear() - 105)
                                ).reverse()}
                                onChange={onDOBChange.bind(this)}
                                value={dobYear}
                            />
                        </Form.Field>
                    </Flex>

                    <Flex className="FormColumn" direction="column" gap="3">
                        <Form.Field className="FormField" name="genderHidden">
                            <div className="FormLabelOuter">
                                <Form.Label className="FormLabel">Gender</Form.Label>
                            </div>
                            <Form.Control asChild>
                                <input
                                    type="hidden"
                                    name="genderHidden"
                                    {...register('genderHidden')}
                                />
                            </Form.Control>
                            <SelectElement
                                name="gender"
                                label="Gender"
                                placeholder="Gender"
                                data={['Male', 'Female']}
                                onChange={onGenderChange}
                                value={gender}
                            />
                        </Form.Field>
                    </Flex>

                    <Separator className="Empty" orientation="horizontal" size="4" />

                    <Flex className="FormColumn" direction="column" gap="3">
                        <Form.Field className="FormField" name="address1">
                            <div className="FormLabelOuter">
                                <Form.Label className="FormLabel">Address</Form.Label>
                            </div>
                            <Form.Control asChild>
                                <TextField.Input name="address1" {...register('address1')} />
                            </Form.Control>
                        </Form.Field>
                    </Flex>

                    <Flex className="FormColumn" direction="column" gap="3">
                        <Form.Field className="FormField" name="postcode">
                            <div className="FormLabelOuter">
                                <Form.Label className="FormLabel">Postcode</Form.Label>
                            </div>
                            <Form.Control asChild>
                                <TextField.Input name="postcode" {...register('postcode')} />
                            </Form.Control>
                        </Form.Field>
                    </Flex>

                    <Separator className="Empty" orientation="horizontal" size="4" />

                    <Flex className="FormColumn" direction="column" gap="3">
                        <Form.Field className="FormField" name="countryHidden">
                            <div className="FormLabelOuter">
                                <Form.Label className="FormLabel">Country of Birth</Form.Label>
                            </div>
                            <Form.Control asChild>
                                <input
                                    type="hidden"
                                    name="countryHidden"
                                    value={country}
                                    {...register('countryHidden')}
                                />
                            </Form.Control>
                            <SelectElement
                                name="country"
                                label="Country"
                                placeholder="Country"
                                data={(countryData.data ?? []).map((rec) => rec.name)}
                                value={country}
                                onChange={onCountryChange.bind(this)}
                            />
                        </Form.Field>
                    </Flex>

                    <Flex className="FormColumn" direction="column" gap="3">
                        <Form.Field className="FormField" name="telephone">
                            <div className="FormLabelOuter">
                                <Form.Label className="FormLabel">Telephone No.</Form.Label>
                            </div>
                            <Form.Control asChild>
                                <TextField.Input name="telephone" {...register('telephone')} />
                            </Form.Control>
                        </Form.Field>
                    </Flex>

                    <Separator className="Empty" orientation="horizontal" size="4" />

                    <Flex className="FormColumn" direction="column" gap="3">
                        <Form.Field className="FormField" name="maritalHidden">
                            <div className="FormLabelOuter">
                                <Form.Label className="FormLabel">Marital Status</Form.Label>
                            </div>
                            <Form.Control asChild>
                                <input
                                    type="hidden"
                                    name="maritalHidden"
                                    value={marital}
                                    {...register('maritalHidden')}
                                />
                            </Form.Control>
                            <SelectElement
                                name="marital"
                                label="Marital"
                                placeholder="Marital"
                                data={(maritalData.data ?? []).map((rec) => rec.name).sort()}
                                value={marital}
                                onChange={onMaritalChange.bind(this)}
                            />
                        </Form.Field>
                    </Flex>

                    <Separator className="FormSeparator" orientation="horizontal" size="4" />

                    <Strong className="FormSectionTitle">Services</Strong>

                    <Flex className="FormColumn" direction="column" gap="3">
                        <Form.Field className="FormField" name="serviceHidden">
                            <div className="FormLabelOuter">
                                <Form.Label className="FormLabel">Service/Project</Form.Label>
                                <Form.Label className="FormSubLabel">
                                    Tick all that apply
                                </Form.Label>
                            </div>
                            <Form.Control asChild>
                                <input
                                    type="hidden"
                                    name="serviceHidden"
                                    value={service}
                                    {...register('serviceHidden')}
                                />
                            </Form.Control>
                            <CheckboxList
                                name="service"
                                options={serviceData.data?.map((record) => record.name).sort()}
                                value={service}
                                onChange={onServiceChange}
                            />
                            <div
                                className="TextOther"
                                style={{ display: showServiceOther ? 'block' : 'none' }}
                            >
                                <TextField.Input
                                    placeholder="Other..."
                                    name="service_other"
                                    {...register('service_other')}
                                />
                            </div>
                            <div
                                className="TextOther"
                                style={{ display: showServicePartnershipOther ? 'block' : 'none' }}
                            >
                                <TextField.Input
                                    placeholder="Pro worker/partnership..."
                                    name="servicepartnership_other"
                                    {...register('servicepartnership_other')}
                                />
                            </div>
                        </Form.Field>
                    </Flex>

                    <Flex className="FormColumn" direction="column" gap="3">
                        <Form.Field className="FormField" name="date_services">
                            <div className="FormLabelOuter">
                                <Form.Label className="FormLabel">Service</Form.Label>
                                <Form.Label className="FormSubLabel">
                                    Assign days/dates to this service/project. This is populated by
                                    selecting services from the list.
                                </Form.Label>
                            </div>
                            <Form.Control asChild>
                                <input
                                    type="hidden"
                                    name="date_servicesHidden"
                                    {...register('date_servicesHidden')}
                                />
                            </Form.Control>
                            <SelectElement
                                name="date_services"
                                label="Service"
                                placeholder="Service"
                                data={selectedServices ?? ['None']}
                                disabled={(selectedServices ?? []).length === 0}
                                onChange={onDateServicesChange}
                            />
                        </Form.Field>

                        <Form.Field className="FormField" name="date_services">
                            <div className="FormLabelOuter">
                                <Form.Label className="FormLabel">Funding</Form.Label>
                                <Form.Label className="FormSubLabel">
                                    Any date you add to the calendar will use the funding selected
                                    below. Colour Key under &quot;Date(s) accessing services&quot;.
                                    <Text weight="bold"> Required.</Text>
                                </Form.Label>
                            </div>
                            <Form.Control asChild>
                                <input
                                    type="hidden"
                                    name="date_fundingHidden"
                                    {...register('date_fundingHidden')}
                                />
                            </Form.Control>
                            <SelectElement
                                name="date_funding"
                                label="Funding"
                                placeholder="Funding"
                                data={(
                                    fundingData.data.filter(
                                        (f) => f.name.toLowerCase() !== 'ptf'
                                    ) ?? []
                                )
                                    .map((rec) => rec.name)
                                    .sort()
                                    .map((rec) => {
                                        return {
                                            label: rec,
                                            colour:
                                                rec === 'Council'
                                                    ? 'Pink'
                                                    : rec === 'Private'
                                                      ? 'Blue'
                                                      : null,
                                        };
                                    })}
                                onChange={onDateFundingChange}
                            />
                        </Form.Field>

                        <Form.Field className="FormField" name="date_weekdayHidden">
                            <div className="FormLabelOuter">
                                <Form.Label className="FormLabel">Prefill days</Form.Label>
                                <Form.Label className="FormSubLabel">
                                    Days chosen here will automatically be populated on the
                                    calendar, week on week, for the rest of the year. optional.
                                </Form.Label>
                            </div>
                            <Form.Control asChild>
                                <input
                                    type="hidden"
                                    name="date_weekdayHidden"
                                    {...register('date_weekdayHidden')}
                                />
                            </Form.Control>
                            <SelectElement
                                name="date_prefill"
                                label="Weekday"
                                placeholder="Weekday"
                                data={weekdays}
                                onChange={onDateWeekdayChange}
                            />
                            <Button
                                style={{ 'margin-right': '10px', cursor: 'pointer' }}
                                onClick={(e) => onServiceWeekdayAddDel(e, 'add')}
                            >
                                <PlusIcon width="16" height="16" /> Add
                            </Button>
                            <Button
                                style={{ cursor: 'pointer' }}
                                onClick={(e) => onServiceWeekdayAddDel(e, 'remove')}
                            >
                                <MinusIcon width="16" height="16" /> Remove
                            </Button>
                        </Form.Field>
                    </Flex>

                    <Flex className="FormColumn" direction="column" gap="3">
                        <Form.Field className="FormField" name="calendar">
                            <div className="FormLabelOuter">
                                <Form.Label className="FormLabel">
                                    Expected attendance dates
                                </Form.Label>
                                <Form.Label className="FormSubLabel">
                                    Select your days here.
                                </Form.Label>
                                <Form.Label className="FormSubLabel ColourKey">
                                    <ColourCard colour="Pink" label="Council" />
                                    <ColourCard colour="Blue" label="Private" />
                                </Form.Label>
                            </div>
                            <Form.Control asChild>
                                <input
                                    type="hidden"
                                    name="calendarHidden"
                                    {...register('calendarHidden')}
                                />
                            </Form.Control>
                            <Calendar
                                name="calendar"
                                disabled={(dateService ?? '')?.length === 0}
                                selected={
                                    selectedCalendarDates?.find(
                                        (calendar) => calendar.name === (dateService ?? '')
                                    )?.data ?? []
                                }
                                onChange={onCalendarChange}
                            />
                        </Form.Field>
                    </Flex>

                    <Separator className="FormSeparator" orientation="horizontal" size="4" />

                    <Strong className="FormSectionTitle">Membership (Emergency Food Store)</Strong>

                    <Flex className="FormColumn" direction="column" gap="3">
                        <Form.Field className="FormField" name="membership_no">
                            <div className="FormLabelOuter">
                                <Form.Label className="FormLabel">Membership No.</Form.Label>
                            </div>
                            <Form.Control asChild>
                                <TextField.Input
                                    name="membership_no"
                                    {...register('membership_no')}
                                />
                            </Form.Control>
                        </Form.Field>
                    </Flex>

                    <Flex className="FormColumn MembershipDate" direction="column" gap="3">
                        <Form.Field className="FormField" name="membershipHidden">
                            <div className="FormLabelOuter">
                                <Form.Label className="FormLabel">Membership Date</Form.Label>
                            </div>
                            <Form.Control asChild>
                                <input
                                    type="hidden"
                                    name="membershipHidden"
                                    {...register('membershipHidden')}
                                />
                            </Form.Control>
                            <SelectElement
                                name="membership-day"
                                label="Day"
                                placeholder="Day"
                                data={Array.from(Array(31), (_, x) => x + 1)}
                                onChange={onMembershipChange.bind(this)}
                                value={membershipDay}
                            />
                            <SelectElement
                                name="membership-month"
                                label="Month"
                                placeholder="Month"
                                data={months}
                                onChange={onMembershipChange.bind(this)}
                                value={membershipMonth}
                            />
                            <SelectElement
                                name="membership-year"
                                label="Year"
                                placeholder="Year"
                                data={Array.from(
                                    Array(100),
                                    (_, x) => x + (new Date().getFullYear() - 99)
                                ).reverse()}
                                onChange={onMembershipChange.bind(this)}
                                value={membershipYear}
                            />
                        </Form.Field>
                    </Flex>

                    <Flex className="FormColumn" direction="column" gap="3">
                        <Form.Field className="FormField" name="membershipEndHidden">
                            <div className="FormLabelOuter">
                                <Form.Label className="FormLabel">Membership End Date</Form.Label>
                            </div>
                            <Form.Control asChild>
                                <input
                                    type="hidden"
                                    name="membershipEndHidden"
                                    {...register('membershipEndHidden')}
                                />
                            </Form.Control>
                            <SelectElement
                                name="membership-end-day"
                                label="Day"
                                placeholder="Day"
                                data={Array.from(Array(31), (_, x) => x + 1)}
                                onChange={onMembershipChange.bind(this)}
                                value={membershipEndDay}
                            />
                            <SelectElement
                                name="membership-end-month"
                                label="Month"
                                placeholder="Month"
                                data={months}
                                onChange={onMembershipChange.bind(this)}
                                value={membershipEndMonth}
                            />
                            <SelectElement
                                name="membership-end-year"
                                label="Year"
                                placeholder="Year"
                                data={Array.from(
                                    Array(100),
                                    (_, x) => x + (new Date().getFullYear() - 99)
                                ).reverse()}
                                onChange={onMembershipChange.bind(this)}
                                value={membershipEndYear}
                            />
                        </Form.Field>
                    </Flex>

                    <Separator className="FormSeparator" orientation="horizontal" size="4" />

                    <Strong className="FormSectionTitle">Household</Strong>

                    <Flex className="FormColumn" direction="column" gap="3">
                        <Form.Field className="FormField" name="adults_householdHidden">
                            <div className="FormLabelOuter">
                                <Form.Label className="FormLabel">
                                    Number of adults (incl. client)
                                </Form.Label>
                            </div>
                            <Form.Control asChild>
                                <input
                                    type="hidden"
                                    name="adults_householdHidden"
                                    {...register('adults_householdHidden')}
                                />
                            </Form.Control>
                            <SelectElement
                                name="adults_household"
                                label="Adults"
                                placeholder="Adults"
                                data={[0].concat(Array.from(Array(20), (_, x) => x + 1))}
                                onChange={onAdultsHouseholdChange.bind(this)}
                                value={adultsHousehold}
                            />
                        </Form.Field>
                    </Flex>

                    <Flex className="FormColumn FormColumnLonger" direction="column" gap="8">
                        <Form.Field className="FormField" name="adultsAgeHidden">
                            <div className="FormLabelOuter">
                                <Form.Label className="FormLabel">
                                    Number of adults by age
                                </Form.Label>
                            </div>
                            <Form.Control asChild>
                                <input
                                    type="hidden"
                                    name="adultsAgeHidden"
                                    {...register('adultsAgeHidden')}
                                />
                            </Form.Control>
                            <SelectRange
                                name="childAge"
                                ranges={adultAgeData.data
                                    ?.map((record) => record.name)
                                    .sort(sortByFirstNumber)}
                                onChange={onAdultAgeChange}
                                value={adultAge}
                            />
                        </Form.Field>
                    </Flex>

                    <Separator className="Empty" orientation="horizontal" size="4" />

                    <Flex className="FormColumn" direction="column" gap="3">
                        <Form.Field className="FormField" name="children_householdHidden">
                            <div className="FormLabelOuter">
                                <Form.Label className="FormLabel">Number of children</Form.Label>
                            </div>
                            <Form.Control asChild>
                                <input
                                    type="hidden"
                                    name="children_householdHidden"
                                    {...register('children_householdHidden')}
                                />
                            </Form.Control>
                            <SelectElement
                                name="children_household"
                                label="Children"
                                placeholder="Children"
                                data={[0].concat(Array.from(Array(20), (_, x) => x + 1))}
                                onChange={onChildrenHouseholdChange}
                                value={childrenHousehold}
                            />
                        </Form.Field>
                    </Flex>

                    <Flex className="FormColumn FormColumnLonger" direction="column" gap="8">
                        <Form.Field className="FormField" name="childAgeHidden">
                            <div className="FormLabelOuter">
                                <Form.Label className="FormLabel">
                                    Number of children by age
                                </Form.Label>
                            </div>
                            <Form.Control asChild>
                                <input
                                    type="hidden"
                                    name="childAgeHidden"
                                    {...register('childAgeHidden')}
                                />
                            </Form.Control>
                            <SelectRange
                                name="childAge"
                                ranges={childAgeData.data
                                    ?.map((record) => record.name)
                                    .sort(sortByFirstNumber)}
                                onChange={onChildAgeChange}
                                value={childAge}
                            />
                        </Form.Field>
                    </Flex>

                    <Separator className="Empty" orientation="horizontal" size="4" />

                    <Flex className="FormColumn" direction="column" gap="3">
                        <Form.Field className="FormField" name="housingHidden">
                            <div className="FormLabelOuter">
                                <Form.Label className="FormLabel">Type</Form.Label>
                            </div>
                            <Form.Control asChild>
                                <input
                                    type="hidden"
                                    name="housingHidden"
                                    {...register('housingHidden')}
                                />
                            </Form.Control>
                            <SelectElement
                                name="housing"
                                label="Housing"
                                placeholder="Housing"
                                data={housingData.data?.map((record) => record.name).sort()}
                                onChange={onHousingChange.bind(this)}
                                value={housing}
                            />
                            <div
                                className="TextOther"
                                style={{ display: showHousingOther ? 'block' : 'none' }}
                            >
                                <TextField.Input
                                    placeholder="Other..."
                                    name="housing_other"
                                    {...register('housing_other')}
                                />
                            </div>
                        </Form.Field>
                    </Flex>

                    <Separator className="FormSeparator" orientation="horizontal" size="4" />

                    <Strong className="FormSectionTitle">Work/Skills</Strong>

                    <Flex className="FormColumn" direction="column" gap="3">
                        <Form.Field className="FormField" name="employmentHidden">
                            <div className="FormLabelOuter">
                                <Form.Label className="FormLabel">Employment</Form.Label>
                            </div>
                            <Form.Control asChild>
                                <input
                                    type="hidden"
                                    name="employmentHidden"
                                    {...register('employmentHidden')}
                                />
                            </Form.Control>
                            <SelectElement
                                name="employment"
                                label="Employment"
                                placeholder="Employment"
                                data={(employmentData.data ?? []).map((rec) => rec.name).sort()}
                                onChange={onEmploymentChange.bind(this)}
                                value={employment}
                            />
                            <div
                                className="TextOther"
                                style={{ display: showEmploymentOther ? 'block' : 'none' }}
                            >
                                <TextField.Input
                                    placeholder="Other..."
                                    name="employment_other"
                                    {...register('employment_other')}
                                />
                            </div>
                        </Form.Field>
                    </Flex>

                    <Flex className="FormColumn" direction="column" gap="3">
                        <Form.Field className="FormField" name="skillsHidden">
                            <div className="FormLabelOuter">
                                <Form.Label className="FormLabel">Skills/Training</Form.Label>
                                <Form.Label className="FormSubLabel">
                                    If you are looking for work how do your
                                    skills/training/qualification equip you for work
                                    <br />
                                    Score 1 to 10, with 1 not all and 10 extremely well
                                </Form.Label>
                            </div>
                            <Form.Control asChild>
                                <input
                                    type="hidden"
                                    name="skillsHidden"
                                    {...register('skillsHidden')}
                                />
                            </Form.Control>
                            <SelectElement
                                name="skills"
                                label="Select"
                                placeholder="Select"
                                data={Array.from(Array(10), (_, x) => x + 1).concat('N/A')}
                                onChange={onSkillsChange.bind(this)}
                                value={skills}
                            />
                        </Form.Field>
                    </Flex>

                    <Flex className="FormColumn" direction="column" gap="3">
                        <Form.Field className="FormField" name="workRelatedHelpHidden">
                            <div className="FormLabelOuter">
                                <Form.Label className="FormLabel">
                                    Work related help already accessed
                                </Form.Label>
                            </div>
                            <Form.Control asChild>
                                <input
                                    type="hidden"
                                    name="workRelatedHelpHidden"
                                    {...register('workRelatedHelpHidden')}
                                />
                            </Form.Control>
                            <SelectElement
                                name="workRelatedHelp"
                                label="Select"
                                placeholder="Select"
                                data={['Yes', 'No', 'N/A']}
                                onChange={onWorkRelatedHelpChange.bind(this)}
                                value={workRelatedHelp}
                            />
                            <div
                                className="TextOther"
                                style={{ display: showWorkRelatedHelpOther ? 'block' : 'none' }}
                            >
                                <TextArea
                                    placeholder="Details of help"
                                    name="workRelatedHelp_other"
                                    {...register('workRelatedHelp_other')}
                                />
                            </div>
                        </Form.Field>
                    </Flex>

                    <Separator className="FormSeparator" orientation="horizontal" size="4" />

                    <Strong className="FormSectionTitle">Funding/Mental Health</Strong>

                    <Flex className="FormColumn" direction="column" gap="3">
                        <Form.Field className="FormField" name="fundingHidden">
                            <div className="FormLabelOuter">
                                <Form.Label className="FormLabel">Funding</Form.Label>
                            </div>
                            <Form.Control asChild>
                                <input
                                    type="hidden"
                                    name="fundingHidden"
                                    {...register('fundingHidden')}
                                />
                            </Form.Control>
                            <SelectElement
                                name="funding"
                                label="Funding"
                                placeholder="Funding"
                                data={(fundingData.data ?? []).map((rec) => rec.name).sort()}
                                onChange={onFundingChange.bind(this)}
                                value={funding}
                            />
                        </Form.Field>
                    </Flex>

                    <Flex className="FormColumn" direction="column" gap="3">
                        <Form.Field className="FormField" name="healthHidden">
                            <div className="FormLabelOuter">
                                <Form.Label className="FormLabel">
                                    Mental or general health requirements
                                </Form.Label>
                                <Form.Label className="FormSubLabel">
                                    Tick all that apply
                                </Form.Label>
                            </div>
                            <Form.Control asChild>
                                <input
                                    type="hidden"
                                    name="healthHidden"
                                    {...register('healthHidden')}
                                />
                            </Form.Control>
                            <CheckboxList
                                name="health"
                                options={(healthData.data ?? []).map((rec) => rec.name).sort()}
                                value={health}
                                onChange={onHealthChange.bind(this)}
                            />
                            <div
                                className="TextOther"
                                style={{ display: showHealthOther ? 'block' : 'none' }}
                            >
                                <TextArea
                                    placeholder="Details of health requirements"
                                    name="health_other"
                                    {...register('health_other')}
                                />
                            </div>
                        </Form.Field>
                    </Flex>

                    <Separator className="FormSeparator" orientation="horizontal" size="4" />

                    <Strong className="FormSectionTitle">Energy</Strong>

                    <Flex className="FormColumn" direction="column" gap="3">
                        <Form.Field className="FormField" name="energyKeepingWarmHidden">
                            <div className="FormLabelOuter">
                                <Form.Label className="FormLabel">
                                    Worried about keeping warm at home?
                                </Form.Label>
                            </div>
                            <Form.Control asChild>
                                <input
                                    type="hidden"
                                    name="energyKeepingWarmHidden"
                                    {...register('energyKeepingWarmHidden')}
                                />
                            </Form.Control>
                            <Text as="label" size="2" className="energyKeepingWarmLabel">
                                <Flex gap="2">
                                    <ThreeStateCheckbox
                                        name="energyKeepingWarm"
                                        checked={energyKeepingWarm}
                                        onCheckedChange={onEnergyKeepingWarmChange}
                                    />
                                    {' Yes/No/Not Asked'}
                                </Flex>
                            </Text>
                        </Form.Field>
                    </Flex>

                    <Flex className="FormColumn" direction="column" gap="3">
                        <Form.Field className="FormField" name="energyAccessedInfoHidden">
                            <div className="FormLabelOuter">
                                <Form.Label className="FormLabel">
                                    Accessed any information about energy bills/money saving?
                                </Form.Label>
                            </div>
                            <Form.Control asChild>
                                <input
                                    type="hidden"
                                    name="energyAccessedInfoHidden"
                                    {...register('energyAccessedInfoHidden')}
                                />
                            </Form.Control>
                            <Text as="label" size="2">
                                <Flex gap="2">
                                    <ThreeStateCheckbox
                                        name="energyAccessedInfo"
                                        checked={energyAccessedInfo}
                                        onCheckedChange={onEnergyAccessedInfoChange}
                                    />
                                    {' Yes/No/Not Asked'}
                                </Flex>
                            </Text>
                        </Form.Field>
                    </Flex>

                    <Flex className="FormColumn" direction="column" gap="3">
                        <Form.Field className="FormField" name="energyTryHidden">
                            <div className="FormLabelOuter">
                                <Form.Label className="FormLabel">
                                    How many of the following have you tried at home?
                                </Form.Label>
                                <Form.Label className="FormSubLabel">
                                    Tick all that apply
                                </Form.Label>
                            </div>
                            <Form.Control asChild>
                                <input
                                    type="hidden"
                                    name="energyTryHidden"
                                    {...register('energyTryHidden')}
                                />
                            </Form.Control>
                            <CheckboxList
                                name="energyTry"
                                options={(energyTryData.data ?? []).map((rec) => rec.name).sort()}
                                value={energyTry}
                                onChange={onEnergyTryChange.bind(this)}
                            />
                            <div
                                className="TextOther"
                                style={{ display: showEnergyTryOther ? 'block' : 'none' }}
                            >
                                <TextArea
                                    placeholder="Details of what you've tried"
                                    name="energyTry_other"
                                    {...register('energyTry_other')}
                                />
                            </div>
                        </Form.Field>
                    </Flex>

                    <Separator className="FormSeparator" orientation="horizontal" size="4" />

                    <Strong className="FormSectionTitle">Debt/Benefits</Strong>

                    <Flex className="FormColumn" direction="column" gap="3">
                        <Form.Field className="FormField" name="billHidden">
                            <div className="FormLabelOuter">
                                <Form.Label className="FormLabel">
                                    Worried about paying rent/energy bills, food or other
                                    essentials?
                                </Form.Label>
                                <Form.Label className="FormSubLabel">
                                    Tick all that apply
                                </Form.Label>
                            </div>
                            <Form.Control asChild>
                                <input
                                    type="hidden"
                                    name="billHidden"
                                    {...register('billHidden')}
                                />
                            </Form.Control>
                            <CheckboxList
                                name="bill"
                                options={(billData.data ?? []).map((rec) => rec.name).sort()}
                                value={bill}
                                onChange={onBillChange.bind(this)}
                            />
                            <div
                                className="TextOther"
                                style={{ display: showBillOther ? 'block' : 'none' }}
                            >
                                <TextArea
                                    placeholder="Details"
                                    name="bill_other"
                                    {...register('bill_other')}
                                />
                            </div>
                        </Form.Field>
                    </Flex>

                    <Flex className="FormColumn" direction="column" gap="3">
                        <Form.Field className="FormField" name="debtOutstandingHidden">
                            <div className="FormLabelOuter">
                                <Form.Label className="FormLabel">
                                    Outstanding debt that you may not be able to pay off?
                                </Form.Label>
                            </div>
                            <Form.Control asChild>
                                <input
                                    type="hidden"
                                    name="debtOutstandingHidden"
                                    {...register('debtOutstandingHidden')}
                                />
                            </Form.Control>
                            <Text as="label" size="2">
                                <Flex gap="2">
                                    <ThreeStateCheckbox
                                        name="debtOutstanding"
                                        checked={debtOutstanding}
                                        onCheckedChange={onDebtOutstandingChange}
                                    />
                                    {' Yes/No/Not Asked'}
                                </Flex>
                            </Text>
                        </Form.Field>
                    </Flex>

                    <Flex className="FormColumn" direction="column" gap="3">
                        <Form.Field className="FormField" name="debtBenefitsHidden">
                            <div className="FormLabelOuter">
                                <Form.Label className="FormLabel">Claiming benefits?</Form.Label>
                            </div>
                            <Form.Control asChild>
                                <input
                                    type="hidden"
                                    name="debtBenefitsHidden"
                                    {...register('debtBenefitsHidden')}
                                />
                            </Form.Control>
                            <Text as="label" size="2">
                                <Flex gap="2">
                                    <Checkbox
                                        size="1"
                                        checked={debtBenefits}
                                        onCheckedChange={onDebtBenefitsChange}
                                    />
                                    {' Yes/No'}
                                </Flex>
                            </Text>
                            <div
                                className="TextOther"
                                style={{
                                    display: showDebtBenefitsCommentsOther ? 'block' : 'none',
                                }}
                            >
                                <TextArea
                                    placeholder="Details"
                                    name="debt_benefits_comments"
                                    {...register('debt_benefits_comments')}
                                />
                            </div>
                        </Form.Field>
                    </Flex>

                    <Separator className="Empty" orientation="horizontal" size="4" />

                    <Flex className="FormColumn" direction="column" gap="3">
                        <Form.Field className="FormField" name="debtHelpHidden">
                            <div className="FormLabelOuter">
                                <Form.Label className="FormLabel">
                                    Accessed any help with money management/debt/benefits?
                                </Form.Label>
                            </div>
                            <Form.Control asChild>
                                <input
                                    type="hidden"
                                    name="debtHelpHidden"
                                    {...register('debtHelpHidden')}
                                />
                            </Form.Control>
                            <Text as="label" size="2">
                                <Flex gap="2">
                                    <Checkbox
                                        size="1"
                                        checked={debtHelp}
                                        onCheckedChange={onDebtHelpChange}
                                    />
                                    {' Yes/No'}
                                </Flex>
                            </Text>
                        </Form.Field>
                    </Flex>

                    <Flex className="FormColumn" direction="column" gap="3">
                        <Form.Field className="FormField" name="debtAssistanceHidden">
                            <div className="FormLabelOuter">
                                <Form.Label className="FormLabel">
                                    Other Assistance given to service user
                                </Form.Label>
                                <Form.Label className="FormSubLabel">
                                    Tick all that apply
                                </Form.Label>
                            </div>
                            <Form.Control asChild>
                                <input
                                    type="hidden"
                                    name="debtAssistanceHidden"
                                    {...register('debtAssistanceHidden')}
                                />
                            </Form.Control>
                            <CheckboxList
                                name="debtAssistance"
                                options={[
                                    'Given helping agency details',
                                    'Made an appointment for client at helping agency',
                                ]}
                                value={debtAssistance}
                                onChange={onDebtAssistanceChange.bind(this)}
                            />
                            <div
                                className="TextOther"
                                style={{
                                    display: showDebtAssistanceHelpingAgencyCommentsOther
                                        ? 'block'
                                        : 'none',
                                }}
                            >
                                <TextArea
                                    placeholder="Helping agency details"
                                    name="debt_assistance_helping_agency_comments"
                                    {...register('debt_assistance_helping_agency_comments')}
                                />
                            </div>
                            <div
                                className="TextOther"
                                style={{
                                    display: showDebtAssistanceHelpingAppointmentCommentsOther
                                        ? 'block'
                                        : 'none',
                                }}
                            >
                                <TextArea
                                    placeholder="Details of agency"
                                    name="debt_assistance_helping_appointment_comments"
                                    {...register('debt_assistance_helping_appointment_comments')}
                                />
                            </div>
                        </Form.Field>
                    </Flex>

                    <Separator className="FormSeparator" orientation="horizontal" size="4" />

                    <Strong className="FormSectionTitle">
                        Payment information (where applicable)
                    </Strong>

                    <Flex className="FormColumn" direction="column" gap="3">
                        <Form.Field className="FormField" name="payer_name">
                            <div className="FormLabelOuter">
                                <Form.Label className="FormLabel">Payer Name</Form.Label>
                            </div>
                            <Form.Control asChild>
                                <TextField.Input
                                    name="payer_name"
                                    {...register('payer_name')}
                                    maxLength={128}
                                />
                            </Form.Control>
                        </Form.Field>
                    </Flex>

                    <Flex className="FormColumn" direction="column" gap="3">
                        <Form.Field className="FormField" name="payer_type">
                            <div className="FormLabelOuter">
                                <Form.Label className="FormLabel">Type</Form.Label>
                            </div>
                            <Form.Control asChild>
                                <input
                                    type="hidden"
                                    name="payer_typeHidden"
                                    {...register('payer_typeHidden')}
                                />
                            </Form.Control>
                            <Form.Control asChild>
                                <SelectElement
                                    name="payer_type"
                                    label="Type"
                                    placeholder="Choose"
                                    data={['Cash', 'Cheque', 'BACS']}
                                    onChange={onPayerTypeChange}
                                    value={paymentType}
                                />
                            </Form.Control>
                        </Form.Field>
                    </Flex>

                    <Separator className="Empty" orientation="horizontal" size="4" />

                    <Flex className="FormColumn" direction="column" gap="3">
                        <Form.Field className="FormField" name="payer_address">
                            <div className="FormLabelOuter">
                                <Form.Label className="FormLabel">Payer address</Form.Label>
                            </div>
                            <Form.Control asChild>
                                <TextArea
                                    placeholder=""
                                    name="payer_address"
                                    rows="6"
                                    {...register('payer_address')}
                                />
                            </Form.Control>
                        </Form.Field>
                    </Flex>

                    <Flex className="FormColumn" direction="column" gap="3">
                        <Form.Field className="FormField" name="payer_telephone_number">
                            <div className="FormLabelOuter">
                                <Form.Label className="FormLabel">
                                    Payer telephone number
                                </Form.Label>
                            </div>
                            <Form.Control asChild>
                                <TextField.Input
                                    name="payer_telephone_number"
                                    {...register('payer_telephone_number')}
                                    maxLength={255}
                                />
                            </Form.Control>
                        </Form.Field>
                    </Flex>

                    <Separator className="Empty" orientation="horizontal" size="4" />

                    <Flex className="FormColumn" direction="column" gap="3">
                        <Form.Field className="FormField" name="payer_nok_name">
                            <div className="FormLabelOuter">
                                <Form.Label className="FormLabel">Next of kin name</Form.Label>
                            </div>
                            <Form.Control asChild>
                                <TextField.Input
                                    name="payer_nok_name"
                                    {...register('payer_nok_name')}
                                    maxLength={255}
                                />
                            </Form.Control>
                        </Form.Field>
                    </Flex>

                    <Flex className="FormColumn" direction="column" gap="3">
                        <Form.Field className="FormField" name="payer_nok_contact_details">
                            <div className="FormLabelOuter">
                                <Form.Label className="FormLabel">
                                    Next of kin contact details
                                </Form.Label>
                            </div>
                            <Form.Control asChild>
                                <TextArea
                                    placeholder="Address/Phone number etc."
                                    name="payer_nok_contact_details"
                                    rows="6"
                                    {...register('payer_nok_contact_details')}
                                />
                            </Form.Control>
                        </Form.Field>
                    </Flex>

                    <Separator className="FormSeparator" orientation="horizontal" size="4" />

                    <Strong className="FormSectionTitle">Other</Strong>

                    <Flex className="FormColumn FormColumnLonger" direction="column" gap="6">
                        <Form.Field className="FormField" name="other_info">
                            <div className="FormLabelOuter">
                                <Form.Label className="FormLabel">Other information</Form.Label>
                            </div>
                            <Form.Control asChild>
                                <TextArea
                                    className="BiggerTextArea"
                                    placeholder=""
                                    name="other_info"
                                    {...register('other_info')}
                                />
                            </Form.Control>
                        </Form.Field>
                    </Flex>

                    <Separator className="Empty" orientation="horizontal" size="4" />

                    <Flex className="FormColumn" direction="column" gap="3">
                        <Form.Field className="FormField" name="enabledHidden">
                            <div className="FormLabelOuter">
                                <Form.Label className="FormLabel">&nbsp;</Form.Label>
                            </div>
                            <Form.Control asChild>
                                <input
                                    type="hidden"
                                    name="enabledHidden"
                                    {...register('enabledHidden')}
                                />
                            </Form.Control>
                            <Text as="label" size="2">
                                <Flex gap="2">
                                    <Checkbox
                                        size="1"
                                        checked={enabled}
                                        onCheckedChange={onEnabledChange}
                                    />
                                    {' Active?'}
                                </Flex>
                            </Text>
                        </Form.Field>
                    </Flex>

                    <Separator className="lastSeparator" orientation="horizontal" size="4" />

                    <div className="FormSubmit">
                        <div className="FormSubmitInner">
                            <Form.Submit asChild>
                                <Button className="SubmitButton" type="submit">
                                    Submit
                                </Button>
                            </Form.Submit>

                            <Form.Submit asChild>
                                <Button
                                    variant="soft"
                                    className="SubmitButton"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        navigate(-1);
                                    }}
                                >
                                    Back
                                </Button>
                            </Form.Submit>
                        </div>
                    </div>
                </Form.Root>
            </SignedIn>
        </>
    );
}

export default CustomersForm;
