import React from 'react';
import SelectElement from './SelectElement';
import { Table } from '@radix-ui/themes';
import '../styles/SelectRange.css';

function SelectRange({ name, ranges, onChange, value }) {
    const [valueThis, setValueThis] = React.useState({});
    const [loading, setLoading] = React.useState({ [name]: false });

    const onValueChange = (val, name) => {
        const newValue = { ...valueThis };
        newValue[name.replace('range-', '')] = val;
        setValueThis(newValue);
        onChange(ranges.map((range) => (newValue[range] != null ? newValue[range] : '')).join('-'));
    };

    React.useEffect(() => {
        if (value?.length > 0 && !(loading?.[name] === true)) {
            const newValue = {};
            value.split('-').forEach((part, idx) => {
                if (part?.toString().length > 0) newValue[ranges[idx]] = part;
            });
            setValueThis(newValue);
            setLoading(true);
        }
    }, [value, loading]);

    return (
        <Table.Root className="SelectRange">
            <Table.Header>
                <Table.Row>
                    {(ranges ?? []).map((valueThis) => {
                        return (
                            <Table.ColumnHeaderCell key={`rangeHeader${valueThis.toString()}`}>
                                {valueThis.toString()}
                            </Table.ColumnHeaderCell>
                        );
                    })}
                </Table.Row>
            </Table.Header>

            <Table.Body>
                <Table.Row>
                    {(ranges ?? []).map((range) => (
                        <Table.Cell
                            className="SelectRangeCell"
                            key={`rangeCell${range.toString()}`}
                        >
                            <SelectElement
                                name={`range-${range.toString()}`}
                                label={range.toString()}
                                placeholder={range.toString()}
                                data={Array.from(Array(21), (_, x) => x)}
                                onChange={onValueChange}
                                value={valueThis[range]}
                            />
                        </Table.Cell>
                    ))}
                </Table.Row>
            </Table.Body>
        </Table.Root>
    );
}

export default SelectRange;
