import { SignedOut } from '@clerk/clerk-react';
import { Link } from 'react-router-dom';

function SignedOutLink() {
    return (
        <SignedOut>
            <p className="signedout">
                You have been logged out. <Link to="/">Please click here to log in</Link>.
            </p>
        </SignedOut>
    );
}

export default SignedOutLink;
