import { useEffect, useState } from 'react';
import { authenticatedFetch } from './helpers/fetch';
import Loading from 'react-fullscreen-loading';
import { SignedIn, useAuth } from '@clerk/clerk-react';
import { Heading, Text, Link } from '@radix-ui/themes';
import { PlusIcon, MinusIcon } from '@radix-ui/react-icons';
import SignedOutLink from './components/SignedOut';
import SelectElement from './components/SelectElement';
import Breadcrumb from './components/Breadcrumb';
import AgeAndGender from './components/reports/AgeAndGender';
import WorriesAboutBills from './components/reports/WorriesAboutBills';
import ResidenceAndEmploymentSituatation from './components/reports/ResidenceAndEmploymentSituatation';
import HelpWithMoneyAdviceBills from './components/reports/HelpWithMoneyAdviceBills';
import DebtsEnergyAdviceWarmth from './components/reports/DebtsEnergyAdviceWarmth';
import Filters from './components/Filters';
import { applyFiltersToCustomerData } from './helpers/data.js';
import './styles/Reports.css';

function Reports() {
    const { getToken, isLoaded, isSignedIn } = useAuth();

    const links = [{ url: '/reports', text: 'Reports and Charts' }];

    const lookups = [
        'country',
        'service',
        'funding',
        'marital',
        'adultAge',
        'childAge',
        'housing',
        'employment',
        'health',
        'energyTry',
        'bill',
        'datestatus',
    ];

    const lookupDataState = {};
    lookups.forEach((lookup) => {
        lookupDataState[lookup] = { data: [], isLoading: false };
    });

    const options = [
        'Age and gender',
        'Worries about bills',
        'Residence and employment situatation',
        'Help with money-advice-bills',
        'Debts, energy advice, warmth',
    ];

    const [showFilter, setShowFilter] = useState(localStorage.getItem('showFilter') || false);

    const [graphType, setGraphType] = useState(options[0]);
    const [customerData, setCustomerData] = useState({ data: [], isLoading: false });
    const [serviceData, setServiceData] = useState({ data: [], isLoading: false });

    const [services, setServices] = useState([]);
    const [status, setStatus] = useState(localStorage.getItem('status') || 'All');
    const [service, setService] = useState(localStorage.getItem('service') || 'All');
    const [filtersUpdated, setFiltersUpdated] = useState(false);

    const [attendanceData, setAttendanceData] = useState({ data: [], isLoading: false });
    const [customerBillData, setCustomerBillData] = useState({ data: [], isLoading: false });
    const [customerEnergyTryData, setCustomerEnergyTryData] = useState({
        data: [],
        isLoading: false,
    });
    const [customerHealthData, setCustomerHealthData] = useState({ data: [], isLoading: false });
    const [customerAdultAgeData, setCustomerAdultAgeData] = useState({
        data: [],
        isLoading: false,
    });
    const [customerChildAgeData, setCustomerChildAgeData] = useState({
        data: [],
        isLoading: false,
    });

    const [currentLookup, setCurrentLookup] = useState();
    const [lookupData, setLookupData] = useState(lookupDataState);

    const [filteredCustomerData, setFilteredCustomerData] = useState({ data: [] });

    const updateWithFilters = async () => {
        console.log('filters changed', filtersUpdated);

        const filters = localStorage.getItem('filters')
            ? JSON.parse(localStorage.getItem('filters'))
            : {};
        const keys = Object.keys(filters);

        console.log('customerData------->', customerData?.data);

        if (keys?.length > 0) {
            const filtered = applyFiltersToCustomerData(customerData?.data, service, filters, {
                serviceData: serviceData?.data ?? [],
                attendanceData: attendanceData?.data ?? [],
                customerBillData: customerBillData?.data ?? [],
                customerEnergyTryData: customerEnergyTryData?.data ?? [],
                customerHealthData: customerHealthData?.data ?? [],
                customerAdultAgeData: customerAdultAgeData?.data ?? [],
                customerChildAgeData: customerChildAgeData?.data ?? [],
                lookupData,
            });

            setFilteredCustomerData({ data: filtered });
        } else {
            setFilteredCustomerData(customerData);
        }
    };

    const load = async () => {
        if (isLoaded && isSignedIn && status?.length > 0 && service?.length > 0) {
            setCustomerData({ data: [], isLoading: true });
            authenticatedFetch(
                `${
                    window.location.origin
                }/api/data/customer?status=${status.toLowerCase()}&service=${service.toLowerCase()}`,
                await getToken()
            )
                .then((res) => res.json())
                .then((data) => {
                    setCustomerData({ data: data, isLoading: false });
                });

            setServiceData({ data: [], isLoading: true });
            authenticatedFetch(
                `${window.location.origin}/api/data/customer/0/services`,
                await getToken()
            )
                .then((res) => res.json())
                .then((data) => {
                    setServiceData({ data: data, isLoading: false });
                });
        }
    };

    useEffect(() => {
        load();
    }, [isLoaded, isSignedIn, status, service]);

    useEffect(() => {
        if (filtersUpdated) {
            updateWithFilters();
            setFiltersUpdated(false);
        }
    }, [isLoaded, isSignedIn, filtersUpdated, service]);

    useEffect(() => {
        updateWithFilters();
    }, [customerData?.data]);

    useEffect(() => {
        setServices(
            ['Please select...'].concat(
                [...new Set(serviceData?.data?.map((record) => record.name) ?? [])].sort()
            )
        );
    }, [serviceData?.data]);

    useEffect(() => {
        const load = async () => {
            if (isLoaded && isSignedIn) {
                setAttendanceData({ data: [], isLoading: true });
                authenticatedFetch(
                    `${window.location.origin}/api/data/customer/0/attendance`,
                    await getToken()
                )
                    .then((res) => res.json())
                    .then((data) => {
                        setAttendanceData({ data: data, isLoading: false });
                    });
            }
        };
        load();
    }, [isLoaded, isSignedIn]);

    useEffect(() => {
        const load = async () => {
            if (isLoaded && isSignedIn) {
                setCustomerBillData({ data: [], isLoading: true });
                authenticatedFetch(
                    `${window.location.origin}/api/data/customer/0/bills`,
                    await getToken()
                )
                    .then((res) => res.json())
                    .then((data) => {
                        setCustomerBillData({ data: data, isLoading: false });
                    });
            }
        };
        load();
    }, [isLoaded, isSignedIn]);

    useEffect(() => {
        const load = async () => {
            if (isLoaded && isSignedIn) {
                setCustomerEnergyTryData({ data: [], isLoading: true });
                authenticatedFetch(
                    `${window.location.origin}/api/data/customer/0/energies`,
                    await getToken()
                )
                    .then((res) => res.json())
                    .then((data) => {
                        setCustomerEnergyTryData({ data: data, isLoading: false });
                    });
            }
        };
        load();
    }, [isLoaded, isSignedIn]);

    useEffect(() => {
        const load = async () => {
            if (isLoaded && isSignedIn) {
                setCustomerHealthData({ data: [], isLoading: true });
                authenticatedFetch(
                    `${window.location.origin}/api/data/customer/0/healths`,
                    await getToken()
                )
                    .then((res) => res.json())
                    .then((data) => {
                        setCustomerHealthData({ data: data, isLoading: false });
                    });
            }
        };
        load();
    }, [isLoaded, isSignedIn]);

    useEffect(() => {
        const load = async () => {
            if (isLoaded && isSignedIn) {
                setCustomerAdultAgeData({ data: [], isLoading: true });
                authenticatedFetch(
                    `${window.location.origin}/api/data/customer/0/ages/adultAge`,
                    await getToken()
                )
                    .then((res) => res.json())
                    .then((data) => {
                        setCustomerAdultAgeData({ data: data, isLoading: false });
                    });
            }
        };
        load();
    }, [isLoaded, isSignedIn]);

    useEffect(() => {
        const load = async () => {
            if (isLoaded && isSignedIn) {
                setCustomerChildAgeData({ data: [], isLoading: true });
                authenticatedFetch(
                    `${window.location.origin}/api/data/customer/0/ages/childAge`,
                    await getToken()
                )
                    .then((res) => res.json())
                    .then((data) => {
                        setCustomerChildAgeData({ data: data, isLoading: false });
                    });
            }
        };
        load();
    }, [isLoaded, isSignedIn]);

    useEffect(() => {
        const load = async () => {
            if (isLoaded && isSignedIn) {
                if (currentLookup !== 'end') {
                    if (!currentLookup) {
                        setCurrentLookup(lookups[0]);
                    } else {
                        const lk = { ...lookupData };
                        lk[currentLookup] = { data: [], isLoading: true };
                        setLookupData(lk);
                        authenticatedFetch(
                            `${window.location.origin}/api/lookup/${currentLookup}`,
                            await getToken()
                        )
                            .then((res) => res.json())
                            .then((data) => {
                                lk[currentLookup] = { data: data, isLoading: false };
                                setLookupData(lk);

                                const idx = lookups.findIndex((el) => el === currentLookup);
                                if (lookups.length - 1 === idx) {
                                    setCurrentLookup('end');
                                } else {
                                    setCurrentLookup(lookups[idx + 1]);
                                }
                            });
                    }
                }
            }
        };
        load();
    }, [isLoaded, isSignedIn, currentLookup]);

    const onStatusChange = (val) => {
        setStatus(val);
        localStorage.setItem('status', val);
    };

    const onServiceChange = (val) => {
        setService(val);
        localStorage.setItem('service', val);
    };

    const onGraphTypeChange = (val) => {
        setGraphType(val);
        console.log('filteredCustomerData', filteredCustomerData);
    };

    const onShowFilterChange = () => {
        const val = !showFilter;
        setShowFilter(val);
        localStorage.setItem('showFilter', val);
    };

    if (customerData.isLoading || serviceData.isLoading)
        return <Loading loading loaderColor="#3498db" />;

    let GraphComponent = <></>;
    switch (graphType) {
        case 'Age and gender':
            GraphComponent = AgeAndGender;
            break;
        case 'Worries about bills':
            GraphComponent = WorriesAboutBills;
            break;
        case 'Residence and employment situatation':
            GraphComponent = ResidenceAndEmploymentSituatation;
            break;
        case 'Help with money-advice-bills':
            GraphComponent = HelpWithMoneyAdviceBills;
            break;
        case 'Debts, energy advice, warmth':
            GraphComponent = DebtsEnergyAdviceWarmth;
            break;
    }

    return (
        <>
            <SignedOutLink />
            <SignedIn>
                <div className="page-header">
                    <Heading>Reports and Charts</Heading>

                    <div>
                        <Text className="SubLabel">Status</Text>
                        <SelectElement
                            name="status"
                            label="Status"
                            placeholder="Status"
                            data={['All', 'Active', 'Inactive']}
                            onChange={onStatusChange}
                            value={status}
                        />
                    </div>

                    <div>
                        <Text className="SubLabel">Service</Text>
                        <SelectElement
                            name="service"
                            label="Service"
                            placeholder="Service"
                            data={services}
                            onChange={onServiceChange}
                            value={service}
                        />
                    </div>

                    <div>
                        <Text className="SubLabel">Graph Type</Text>
                        <SelectElement
                            name="type"
                            label="Type"
                            placeholder="Type"
                            data={options}
                            value={graphType}
                            onChange={onGraphTypeChange}
                        />
                    </div>

                    <div>
                        <Link
                            href="#"
                            className="ShowFilterLink"
                            onClick={(e) => {
                                e.preventDefault();
                                onShowFilterChange();
                            }}
                            size="1"
                        >
                            {showFilter ? (
                                <MinusIcon width="12" height="12" />
                            ) : (
                                <PlusIcon width="12" height="12" />
                            )}
                            More filters...
                        </Link>
                    </div>

                    {showFilter && <Filters onChange={() => setFiltersUpdated(true)} />}
                </div>

                <Breadcrumb links={links} />

                {filteredCustomerData?.data?.length > 0 && (
                    <GraphComponent customerData={filteredCustomerData} />
                )}
                {filteredCustomerData?.data?.length === 0 && <div className="EmptyBox" />}
            </SignedIn>
        </>
    );
}

export default Reports;
