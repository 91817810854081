import { Link } from 'react-router-dom';
import { SignedIn, UserButton } from '@clerk/clerk-react';
import '../styles/Header.css';

function Header() {
    return (
        <header>
            <SignedIn>
                <Link className="logo" title="Back to main menu" to="/">
                    <img src="/st-catherines-church-logo.png" alt="St. Catherine's Church" />
                </Link>
            </SignedIn>
            <nav>
                <SignedIn>
                    <UserButton afterSignOutUrl="/" />
                </SignedIn>
            </nav>
        </header>
    );
}

export default Header;
