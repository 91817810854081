import { useLocation } from 'react-router-dom';
import { Outlet } from 'react-router-dom';
import { SignedIn } from '@clerk/clerk-react';
import Header from './Header';
import Footer from './Footer';
import '@radix-ui/themes/styles.css';
import '../styles/App.css';
import { Theme } from '@radix-ui/themes';
import { isNumber } from '../helpers/data.js';

function Layout() {
    const location = useLocation();
    const { pathname } = location;
    let page = pathname.split('/');
    page.shift();
    page = page.filter((p) => !isNumber(p));
    page = page.join('__').toLocaleLowerCase();

    page = page?.length === 0 ? 'home' : page;

    return (
        <>
            <SignedIn>
                <Header />
            </SignedIn>

            <Theme>
                <div className={`app ${page}`}>
                    <Outlet />
                </div>
            </Theme>

            <SignedIn>
                <Footer />
            </SignedIn>
        </>
    );
}

export default Layout;
