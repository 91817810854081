import { useEffect, useState } from 'react';
import { authenticatedFetch } from './helpers/fetch';
import { nameSearch } from './helpers/data';
import { SignedIn, useAuth } from '@clerk/clerk-react';
import {
    Heading,
    Table,
    Button,
    Callout,
    AlertDialog,
    Flex,
    Text,
    TextField,
} from '@radix-ui/themes';
import * as Tooltip from '@radix-ui/react-tooltip';
import { PlusIcon, MagnifyingGlassIcon } from '@radix-ui/react-icons';
import { useNavigate } from 'react-router-dom';
import { InfoCircledIcon, TrashIcon, Pencil2Icon } from '@radix-ui/react-icons';
import SignedOutLink from './components/SignedOut';
import Breadcrumb from './components/Breadcrumb';
import SelectElement from './components/SelectElement';
import Loading from 'react-fullscreen-loading';
import { format } from 'date-fns';
import { useQuery as useQueryString } from './helpers/routing.js';
import './styles/Tooltip.css';
import './styles/Customers.css';

function Customers() {
    const links = [{ url: '/customers', text: 'Customer Data' }];

    let navigate = useNavigate();
    let query = useQueryString();

    const { getToken, isLoaded, isSignedIn } = useAuth();

    const [showSuccess, setShowSuccess] = useState(false);
    const [customerData, setCustomerData] = useState({ data: [], isLoading: false });
    const [serviceData, setServiceData] = useState({ data: [], isLoading: false });

    const [customers, setCustomers] = useState([]);

    const [keywords, setKeywords] = useState(localStorage.getItem('search') || '');
    const [status, setStatus] = useState(localStorage.getItem('status') || 'All');
    const [service, setService] = useState(localStorage.getItem('service') || 'All');
    const [services, setServices] = useState([]);

    const load = async () => {
        if (isLoaded && isSignedIn && status?.length > 0 && service?.length > 0) {
            setCustomerData({ data: [], isLoading: true });
            authenticatedFetch(
                `${
                    window.location.origin
                }/api/data/customer?status=${status.toLowerCase()}&service=${service.toLowerCase()}`,
                await getToken()
            )
                .then((res) => res.json())
                .then((data) => {
                    setCustomerData({ data: data, isLoading: false });
                });
        }
    };

    useEffect(() => {
        load();
    }, [isLoaded, isSignedIn, status, service]);

    useEffect(() => {
        if (!customerData.isLoading) {
            if (keywords.length > 0) {
                // filter results
                setCustomers(nameSearch(keywords, customerData.data));
            } else {
                setCustomers([...customerData.data]);
            }
        }
    }, [keywords, customerData]);

    useEffect(() => {
        const load = async () => {
            if (isLoaded && isSignedIn) {
                setServiceData({ data: [], isLoading: true });
                authenticatedFetch(
                    `${window.location.origin}/api/data/customer/0/services`,
                    await getToken()
                )
                    .then((res) => res.json())
                    .then((data) => {
                        setServiceData({ data: data, isLoading: false });
                    });
            }
        };
        load();
    }, [isLoaded, isSignedIn]);

    useEffect(() => {
        setServices(
            ['All'].concat(
                [...new Set(serviceData?.data?.map((record) => record.name) ?? [])].sort()
            )
        );
    }, [serviceData?.data]);

    useEffect(() => {
        const id = query.get('success') > 0 ? query.get('success') : 0;

        if (id > 0) {
            setShowSuccess(true);

            setTimeout(() => {
                setShowSuccess(false);
            }, 6000);
        }
    }, [query]);

    const onSearchChange = (val) => {
        setKeywords(val);
        localStorage.setItem('search', val);
    };

    const onStatusChange = (val) => {
        setStatus(val);
        localStorage.setItem('status', val);
    };

    const onServiceChange = (val) => {
        setService(val);
        localStorage.setItem('service', val);
    };

    const addCustomer = () => {
        navigate('/customers/add');
    };

    if (serviceData.isLoading || customerData.isLoading)
        return <Loading loading loaderColor="#3498db" />;

    return (
        <>
            <SignedOutLink />
            <SignedIn>
                <div className="page-header">
                    <Heading>Customers</Heading>

                    <div>
                        <Text className="SubLabel">Search</Text>
                        <TextField.Root placeholder="Search by name...">
                            <TextField.Slot>
                                <MagnifyingGlassIcon height="16" width="16" />
                            </TextField.Slot>
                            <TextField.Input
                                name="search"
                                maxLength={128}
                                onChange={(e) => onSearchChange(e.target.value)}
                                value={keywords}
                            />
                        </TextField.Root>
                    </div>

                    <div>
                        <Text className="SubLabel">Status</Text>
                        <SelectElement
                            name="status"
                            label="Status"
                            placeholder="Status"
                            data={['All', 'Active', 'Inactive']}
                            onChange={onStatusChange}
                            value={status}
                        />
                    </div>

                    <div>
                        <Text className="SubLabel">Service</Text>
                        <SelectElement
                            name="service"
                            label="Service"
                            placeholder="Service"
                            data={services}
                            onChange={onServiceChange}
                            value={service}
                        />
                    </div>

                    <Button onClick={() => addCustomer()}>
                        <PlusIcon width="16" height="16" /> Add Customer
                    </Button>
                </div>

                <Breadcrumb links={links} />

                {showSuccess && (
                    <Callout.Root color="green" className="notification-callout">
                        <Callout.Icon>
                            <InfoCircledIcon />
                        </Callout.Icon>
                        <Callout.Text>The Customer has been Add/Updated.</Callout.Text>
                    </Callout.Root>
                )}

                {customers?.length === 0 && (
                    <Text style={{ margin: '40px 0 0 20px' }}>
                        No customers currently available for these filters.
                    </Text>
                )}

                {customers?.length > 0 && (
                    <Table.Root className="customer-data">
                        <Table.Header>
                            <Table.Row>
                                <Table.ColumnHeaderCell>Full name</Table.ColumnHeaderCell>
                                <Table.ColumnHeaderCell>DOB</Table.ColumnHeaderCell>
                                <Table.ColumnHeaderCell>Gender</Table.ColumnHeaderCell>
                                <Table.ColumnHeaderCell>Address</Table.ColumnHeaderCell>
                                <Table.ColumnHeaderCell>Telephone</Table.ColumnHeaderCell>
                                <Table.ColumnHeaderCell>&nbsp;</Table.ColumnHeaderCell>
                            </Table.Row>
                        </Table.Header>

                        <Table.Body>
                            {customers.map((customer, idx) => (
                                <Table.Row key={`customer-key-${idx}`}>
                                    <Table.RowHeaderCell>
                                        {customer.last_name + ', ' + customer.first_name}
                                    </Table.RowHeaderCell>
                                    <Table.Cell>
                                        {customer.dob
                                            ? format(new Date(customer.dob), 'do MMMM yyyy')
                                            : ''}
                                    </Table.Cell>
                                    <Table.Cell>
                                        {customer.gender === 'm' ? 'Male' : 'Female'}
                                    </Table.Cell>
                                    <Table.Cell>
                                        {customer.address1?.length > 0
                                            ? `${customer.address1}, `
                                            : ''}
                                        {customer.postcode}
                                    </Table.Cell>
                                    <Table.Cell>{customer.telephone}</Table.Cell>
                                    <Table.Cell className="tools-cell">
                                        <Tooltip.Provider>
                                            <Tooltip.Root>
                                                <Tooltip.Trigger>
                                                    <Button
                                                        variant="soft"
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            navigate(
                                                                `/customers/add/${customer.id}`
                                                            );
                                                        }}
                                                    >
                                                        <Pencil2Icon width="16" height="16" />
                                                    </Button>
                                                </Tooltip.Trigger>
                                                <Tooltip.Portal>
                                                    <Tooltip.Content
                                                        className="TooltipContent"
                                                        sideOffset={5}
                                                    >
                                                        Edit customer
                                                        <Tooltip.Arrow className="TooltipArrow" />
                                                    </Tooltip.Content>
                                                </Tooltip.Portal>
                                            </Tooltip.Root>
                                        </Tooltip.Provider>

                                        <Tooltip.Provider>
                                            <Tooltip.Root>
                                                <Tooltip.Trigger>
                                                    <AlertDialog.Root>
                                                        <AlertDialog.Trigger>
                                                            <Button variant="soft">
                                                                <TrashIcon width="16" height="16" />
                                                            </Button>
                                                        </AlertDialog.Trigger>
                                                        <AlertDialog.Content
                                                            style={{ maxWidth: 450 }}
                                                        >
                                                            <AlertDialog.Title>
                                                                Delete customer
                                                            </AlertDialog.Title>
                                                            <AlertDialog.Description size="2">
                                                                Are you sure you want to delete this
                                                                customer? It can NOT be undone.
                                                            </AlertDialog.Description>

                                                            <Flex gap="3" mt="4" justify="end">
                                                                <AlertDialog.Cancel>
                                                                    <Button
                                                                        variant="soft"
                                                                        color="gray"
                                                                    >
                                                                        Cancel
                                                                    </Button>
                                                                </AlertDialog.Cancel>
                                                                <AlertDialog.Action>
                                                                    <Button
                                                                        variant="solid"
                                                                        color="red"
                                                                        onClick={async () => {
                                                                            await authenticatedFetch(
                                                                                `${window.location.origin}/api/data/customer/${customer.id}`,
                                                                                await getToken(),
                                                                                {
                                                                                    method: 'DELETE',
                                                                                }
                                                                            );

                                                                            load();
                                                                        }}
                                                                    >
                                                                        Delete
                                                                    </Button>
                                                                </AlertDialog.Action>
                                                            </Flex>
                                                        </AlertDialog.Content>
                                                    </AlertDialog.Root>
                                                </Tooltip.Trigger>
                                                <Tooltip.Portal>
                                                    <Tooltip.Content
                                                        className="TooltipContent"
                                                        sideOffset={5}
                                                    >
                                                        Delete customer
                                                        <Tooltip.Arrow className="TooltipArrow" />
                                                    </Tooltip.Content>
                                                </Tooltip.Portal>
                                            </Tooltip.Root>
                                        </Tooltip.Provider>
                                    </Table.Cell>
                                </Table.Row>
                            ))}
                        </Table.Body>
                    </Table.Root>
                )}
            </SignedIn>
        </>
    );
}

export default Customers;
