import { useEffect, useState } from 'react';
import { useAuth } from '@clerk/clerk-react';
import { authenticatedFetch } from '../helpers/fetch';
import { months } from '../helpers/data';
import Loading from 'react-fullscreen-loading';
import SelectElement from '../components/SelectElement';
import { Text, Flex, Button } from '@radix-ui/themes';

function Filters({ onChange }) {
    const { getToken, isLoaded, isSignedIn } = useAuth();

    const [adultAgeData, setAdultAgeData] = useState({ data: [], isLoading: false });
    const [childAgeData, setChildAgeData] = useState({ data: [], isLoading: false });
    const [housingData, setHousingData] = useState({ data: [], isLoading: false });
    const [employmentData, setEmploymentData] = useState({ data: [], isLoading: false });
    const [fundingData, setFundingData] = useState({ data: [], isLoading: false });
    const [healthData, setHealthData] = useState({ data: [], isLoading: false });
    const [energyTryData, setEnergyTryData] = useState({ data: [], isLoading: false });
    const [billData, setBillData] = useState({ data: [], isLoading: false });

    const [filters, setFilters] = useState(
        localStorage.getItem('filters') ? JSON.parse(localStorage.getItem('filters')) : {}
    );

    const load = async () => {
        if (isLoaded && isSignedIn) {
            setAdultAgeData({ data: [], isLoading: true });
            authenticatedFetch(`${window.location.origin}/api/lookup/adultAge`, await getToken())
                .then((res) => res.json())
                .then((data) => {
                    setAdultAgeData({ data: data, isLoading: false });
                });

            setChildAgeData({ data: [], isLoading: true });
            authenticatedFetch(`${window.location.origin}/api/lookup/childAge`, await getToken())
                .then((res) => res.json())
                .then((data) => {
                    setChildAgeData({ data: data, isLoading: false });
                });

            setHousingData({ data: [], isLoading: true });
            authenticatedFetch(`${window.location.origin}/api/lookup/housing`, await getToken())
                .then((res) => res.json())
                .then((data) => {
                    setHousingData({ data: data, isLoading: false });
                });

            setEmploymentData({ data: [], isLoading: true });
            authenticatedFetch(`${window.location.origin}/api/lookup/employment`, await getToken())
                .then((res) => res.json())
                .then((data) => {
                    setEmploymentData({ data: data, isLoading: false });
                });

            setFundingData({ data: [], isLoading: true });
            authenticatedFetch(`${window.location.origin}/api/lookup/funding`, await getToken())
                .then((res) => res.json())
                .then((data) => {
                    setFundingData({ data: data, isLoading: false });
                });

            setHealthData({ data: [], isLoading: true });
            authenticatedFetch(`${window.location.origin}/api/lookup/health`, await getToken())
                .then((res) => res.json())
                .then((data) => {
                    setHealthData({ data: data, isLoading: false });
                });

            setBillData({ data: [], isLoading: true });
            authenticatedFetch(`${window.location.origin}/api/lookup/bill`, await getToken())
                .then((res) => res.json())
                .then((data) => {
                    setBillData({ data: data, isLoading: false });
                });

            setEnergyTryData({ data: [], isLoading: true });
            authenticatedFetch(`${window.location.origin}/api/lookup/energyTry`, await getToken())
                .then((res) => res.json())
                .then((data) => {
                    setEnergyTryData({ data: data, isLoading: false });
                });
        }
    };

    useEffect(() => {
        load();
    }, [isLoaded, isSignedIn]);

    if (
        adultAgeData.isLoading ||
        childAgeData.isLoading ||
        housingData.isLoading ||
        employmentData.isLoading ||
        fundingData.isLoading ||
        healthData.isLoading ||
        billData.isLoading ||
        energyTryData.isLoading
    )
        return <Loading loading loaderColor="#3498db" />;

    const onFilterChange = (val, name) => {
        const cloned = { ...filters };
        cloned[name] = val;
        setFilters(cloned);

        localStorage.setItem('filters', JSON.stringify(cloned));

        if (onChange) {
            onChange();
        }
    };

    return (
        <>
            <div className="MoreFilters">
                <Flex gap="3">
                    <div className="DateBox">
                        <Text className="SubLabel">Membership From (EFS)</Text>
                        <SelectElement
                            className={['ShortDropdown', 'DateShortDropdown']}
                            name="from-day"
                            label="Day"
                            placeholder="Day"
                            data={Array.from(Array(31), (_, x) => x + 1)}
                            value={filters?.['from-day']?.toString()}
                            onChange={onFilterChange}
                        />
                        <SelectElement
                            className={['ShortDropdown', 'DateShortDropdown']}
                            name="from-month"
                            label="Month"
                            placeholder="Month"
                            data={months}
                            value={filters?.['from-month']?.toString()}
                            onChange={onFilterChange}
                        />
                        <SelectElement
                            className={['ShortDropdown', 'DateShortDropdown']}
                            name="from-year"
                            label="Year"
                            placeholder="Year"
                            data={Array.from(
                                Array(100),
                                (_, x) => x + (new Date().getFullYear() - 99)
                            ).reverse()}
                            value={filters?.['from-year']?.toString()}
                            onChange={onFilterChange}
                        />
                    </div>
                    <div className="DateBox">
                        <Text className="SubLabel">Membership To (EFS)</Text>
                        <SelectElement
                            className={['ShortDropdown', 'DateShortDropdown']}
                            name="to-day"
                            label="Day"
                            placeholder="Day"
                            data={Array.from(Array(31), (_, x) => x + 1)}
                            value={filters?.['to-day']?.toString()}
                            onChange={onFilterChange}
                        />
                        <SelectElement
                            className={['ShortDropdown', 'DateShortDropdown']}
                            name="to-month"
                            label="Month"
                            placeholder="Month"
                            data={months}
                            value={filters?.['to-month']?.toString()}
                            onChange={onFilterChange}
                        />
                        <SelectElement
                            className={['ShortDropdown', 'DateShortDropdown']}
                            name="to-year"
                            label="Year"
                            placeholder="Year"
                            data={Array.from(
                                Array(100),
                                (_, x) => x + (new Date().getFullYear() - 99)
                            ).reverse()}
                            value={filters?.['to-year']?.toString()}
                            onChange={onFilterChange}
                        />
                    </div>

                    <div className="VerticalBar"></div>

                    <div>
                        <Text className="SubLabel">No of adults</Text>
                        <SelectElement
                            className="ShortDropdown"
                            name="adults_household"
                            label="Adults"
                            placeholder="Adults"
                            data={[0].concat(Array.from(Array(20), (_, x) => x + 1))}
                            onChange={onFilterChange}
                            value={filters?.['adults_household']?.toString()}
                        />
                    </div>

                    {adultAgeData?.data?.map((rec) => {
                        return (
                            <div key={`adultage_${rec.id}`}>
                                <Text className="SubLabel">{rec.name}</Text>
                                <SelectElement
                                    className="NotSoShortDropdown"
                                    name={`adultage_${rec.id}`}
                                    label={rec.name}
                                    placeholder={rec.name}
                                    data={[0].concat(Array.from(Array(20), (_, x) => x + 1))}
                                    onChange={onFilterChange}
                                    value={filters?.[`adultage_${rec.id}`]?.toString()}
                                />
                            </div>
                        );
                    })}
                </Flex>
            </div>
            <div className="MoreFilters">
                <Flex gap="3">
                    <div>
                        <Text className="SubLabel">No of children</Text>
                        <SelectElement
                            className="ShortDropdown"
                            name="children_household"
                            label="Children"
                            placeholder="Children"
                            data={[0].concat(Array.from(Array(20), (_, x) => x + 1))}
                            onChange={onFilterChange}
                            value={filters?.['children_household']?.toString()}
                        />
                    </div>

                    {childAgeData?.data?.map((rec) => {
                        return (
                            <div key={`childage_${rec.id}`}>
                                <Text className="SubLabel">{rec.name}</Text>
                                <SelectElement
                                    className="NotSoShortDropdown"
                                    name={`childage_${rec.id}`}
                                    label={rec.name}
                                    placeholder={rec.name}
                                    data={[0].concat(Array.from(Array(20), (_, x) => x + 1))}
                                    onChange={onFilterChange}
                                    value={filters?.[`childage_${rec.id}`]?.toString()}
                                />
                            </div>
                        );
                    })}

                    <div className="VerticalBar"></div>

                    <div>
                        <Text className="SubLabel">Housing</Text>
                        <SelectElement
                            name="housing"
                            label="Housing"
                            placeholder="Housing"
                            data={housingData.data?.map((record) => record.name).sort()}
                            onChange={onFilterChange}
                            value={filters?.['housing']?.toString()}
                        />
                    </div>

                    <div className="VerticalBar"></div>

                    <div>
                        <Text className="SubLabel">Employment</Text>
                        <SelectElement
                            name="employment"
                            label="Employment"
                            placeholder="Employment"
                            data={employmentData.data?.map((record) => record.name).sort()}
                            onChange={onFilterChange}
                            value={filters?.['employment']?.toString()}
                        />
                    </div>

                    <div>
                        <Text className="SubLabel">Skills/Training</Text>
                        <SelectElement
                            className="NotSoShortDropdown"
                            name="skills"
                            label="Select"
                            placeholder="Select"
                            data={Array.from(Array(10), (_, x) => x + 1).concat('N/A')}
                            onChange={onFilterChange}
                            value={filters?.['skills']?.toString()}
                        />
                    </div>

                    <div>
                        <Text className="SubLabel">Work related help</Text>
                        <SelectElement
                            className="NotSoShortDropdown"
                            name="work_related_help"
                            label="Select"
                            placeholder="Select"
                            data={['Yes', 'No', 'N/A']}
                            onChange={onFilterChange}
                            value={filters?.['work_related_help']?.toString()}
                        />
                    </div>
                </Flex>
            </div>
            <div className="MoreFilters">
                <Flex gap="3">
                    <div>
                        <Text className="SubLabel">Health</Text>
                        <SelectElement
                            name="health"
                            label="Health"
                            placeholder="Health"
                            data={(healthData.data ?? []).map((rec) => rec.name).sort()}
                            onChange={onFilterChange}
                            value={filters?.['health']?.toString()}
                        />
                    </div>

                    <div className="VerticalBar"></div>

                    <div>
                        <Text className="SubLabel">Worried about keeping warm?</Text>
                        <SelectElement
                            name="keeping_warm"
                            label="Select"
                            placeholder="Select"
                            data={['Yes', 'No', 'Not asked']}
                            onChange={onFilterChange}
                            value={filters?.['keeping_warm']?.toString()}
                        />
                    </div>

                    <div>
                        <Text className="SubLabel">Energy bills/money Info?</Text>
                        <SelectElement
                            name="energy_bills"
                            label="Select"
                            placeholder="Select"
                            data={['Yes', 'No', 'Not asked']}
                            onChange={onFilterChange}
                            value={filters?.['energy_bills']?.toString()}
                        />
                    </div>

                    <div>
                        <Text className="SubLabel">Tried at home?</Text>
                        <SelectElement
                            name="energy_try"
                            label="Select"
                            placeholder="Select"
                            data={(energyTryData.data ?? []).map((rec) => rec.name).sort()}
                            onChange={onFilterChange}
                            value={filters?.['energy_try']?.toString()}
                        />
                    </div>
                </Flex>
            </div>
            <div className="MoreFilters">
                <Flex gap="3">
                    <div>
                        <Text className="SubLabel">Worried about paying?</Text>
                        <SelectElement
                            name="bill"
                            label="Select"
                            placeholder="Select"
                            data={(billData.data ?? []).map((rec) => rec.name).sort()}
                            onChange={onFilterChange}
                            value={filters?.['bill']?.toString()}
                        />
                    </div>

                    <div>
                        <Text className="SubLabel">Outstanding debt?</Text>
                        <SelectElement
                            className="NotSoShortDropdown"
                            name="outstanding_debt"
                            label="Select"
                            placeholder="Select"
                            data={['Yes', 'No', 'Not asked']}
                            onChange={onFilterChange}
                            value={filters?.['outstanding_debt']?.toString()}
                        />
                    </div>

                    <div>
                        <Text className="SubLabel">Claiming benefits?</Text>
                        <SelectElement
                            className="NotSoShortDropdown"
                            name="benefits"
                            label="Select"
                            placeholder="Select"
                            data={['Yes', 'No']}
                            onChange={onFilterChange}
                            value={filters?.['benefits']?.toString()}
                        />
                    </div>

                    <div>
                        <Text className="SubLabel">Accessed help with money?</Text>
                        <SelectElement
                            className="NotSoShortDropdown"
                            name="money"
                            label="Select"
                            placeholder="Select"
                            data={['Yes', 'No']}
                            onChange={onFilterChange}
                            value={filters?.['money']?.toString()}
                        />
                    </div>

                    <div>
                        <Text className="SubLabel">Other Assistance?</Text>
                        <SelectElement
                            name="other_assistance"
                            label="Select"
                            placeholder="Select"
                            data={[
                                'Given helping agency details',
                                'Made an appointment for client at helping agency',
                            ]}
                            onChange={onFilterChange}
                            value={filters?.['other_assistance']?.toString()}
                        />
                    </div>

                    <div className="VerticalBar"></div>

                    <Button
                        variant="soft"
                        className="SubmitButton"
                        onClick={(e) => {
                            e.preventDefault();
                            localStorage.removeItem('filters');
                            document.location.reload();
                        }}
                    >
                        Reset filters
                    </Button>
                </Flex>
            </div>
        </>
    );
}

export default Filters;
