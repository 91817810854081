import { Box, Card, Inset, Text } from '@radix-ui/themes';
import { Link } from 'react-router-dom';

function MenuItem({ img, alt, url, txt }) {
    return (
        <Box>
            <Link to={url}>
                <Card size="2" className="menuitem">
                    <Inset clip="padding-box" side="top" pb="current">
                        <img src={img} alt={alt} />
                    </Inset>
                    <Text as="p" size="3">
                        {txt}
                    </Text>
                </Card>
            </Link>
        </Box>
    );
}

export default MenuItem;
