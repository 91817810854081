import React from 'react';
import * as ScrollArea from '@radix-ui/react-scroll-area';
import classNames from 'classnames';

import '../styles/ScrollList.css';

const ScrollList = ({ name, label, list, rightList, onClick, onMoveAll }) => {
    return (
        <ScrollArea.Root className="ScrollAreaRoot">
            <ScrollArea.Viewport className="ScrollAreaViewport">
                <div className="Padding">
                    <div className="Text">
                        {label}{' '}
                        <a
                            className="TextSelectAll"
                            href="#"
                            onClick={(e) => {
                                e.preventDefault();
                                onMoveAll(name);
                            }}
                        >
                            move all
                        </a>
                    </div>
                    {list?.map((item) => {
                        const classes = classNames({
                            TagRight: rightList,
                            Tag: true,
                        });
                        return (
                            <div className={classes} key={item} onClick={() => onClick(name, item)}>
                                {item}
                            </div>
                        );
                    })}
                </div>
            </ScrollArea.Viewport>
            <ScrollArea.Scrollbar className="ScrollAreaScrollbar" orientation="vertical">
                <ScrollArea.Thumb className="ScrollAreaThumb" />
            </ScrollArea.Scrollbar>
            <ScrollArea.Scrollbar className="ScrollAreaScrollbar" orientation="horizontal">
                <ScrollArea.Thumb className="ScrollAreaThumb" />
            </ScrollArea.Scrollbar>
            <ScrollArea.Corner className="ScrollAreaCorner" />
        </ScrollArea.Root>
    );
};

export default ScrollList;
