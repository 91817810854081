import React from 'react';
import ReactDOM from 'react-dom/client';
import { ClerkProvider } from '@clerk/clerk-react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './Home';
import Customers from './Customers';
import CustomersForm from './CustomersForm';
import Attendance from './Attendance';
import Exports from './Exports';
import Reports from './Reports';
import Layout from './components/Layout';
import NoMatch from './components/NoMatch';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

const queryClient = new QueryClient();

import './styles/index.css';

ReactDOM.createRoot(document.getElementById('root')).render(
    <React.StrictMode>
        {/* eslint-disable-next-line */}
        <ClerkProvider publishableKey={publishableKey}>
            <QueryClientProvider client={queryClient}>
                <BrowserRouter>
                    <Routes>
                        <Route element={<Layout />}>
                            <Route path="/" index element={<Home />} />
                            <Route path="/customers" element={<Customers />} />
                            <Route path="/customers/add/:id" element={<CustomersForm />} />
                            <Route path="/customers/add" element={<CustomersForm />} />
                            <Route path="/attendance" element={<Attendance />} />
                            <Route path="/reports" element={<Reports />} />
                            <Route path="/exports" element={<Exports />} />
                            <Route path="*" element={<NoMatch />} />
                        </Route>
                    </Routes>
                </BrowserRouter>
            </QueryClientProvider>
        </ClerkProvider>
    </React.StrictMode>
);
