import '../styles/Breadcrumb.css';
import { Link } from 'react-router-dom';

function renderLinks(links) {
    return links.map((link, idx) => {
        if (links.length == idx + 1) {
            return (
                <li key={`breadcrumb-${idx}`}>
                    <span>{link.text}</span>
                </li>
            );
        } else {
            return (
                <li key={`breadcrumb-${idx}`}>
                    <Link to={link.url}>{link.text}</Link>
                </li>
            );
        }
    });
}

function Breadcrumb({ links }) {
    return (
        <nav className="breadcrumb">
            <ul>
                <li>
                    <Link to="/">Home</Link>
                </li>
                {renderLinks(links)}
            </ul>
        </nav>
    );
}

export default Breadcrumb;
