import { useCalendar } from '@h6s/calendar';
import { Table } from '@radix-ui/themes';
import * as Toolbar from '@radix-ui/react-toolbar';
import { weekdays } from '../helpers/data';
import classNames from 'classnames';
import {
    isWithinInterval,
    format,
    addDays,
    subSeconds,
    subMonths,
    subHours,
    addMonths,
    lastDayOfMonth,
} from 'date-fns';
import '../styles/Calendar.css';

let currentDate = new Date();

function Calendar({ name, selected, disabled, onChange }) {
    if (disabled) {
        return <></>;
    }

    const { headers, body, navigation } = useCalendar(currentDate);

    return (
        <>
            <Toolbar.Root className="CalendarToolbar">
                <Toolbar.Button
                    className="ToolbarButton"
                    onClick={() => {
                        currentDate = subMonths(currentDate, 1);
                        navigation.toPrev();
                    }}
                >
                    Previous
                </Toolbar.Button>
                <Toolbar.Link className="ToolbarButtonLink">
                    {format(currentDate, 'MMMM yyyy')}
                </Toolbar.Link>
                <Toolbar.Button
                    className="ToolbarButton"
                    style={{ marginLeft: 'auto' }}
                    onClick={() => {
                        currentDate = addMonths(currentDate, 1);
                        navigation.toNext();
                    }}
                >
                    Next
                </Toolbar.Button>
            </Toolbar.Root>

            <Table.Root className="Calendar">
                <Table.Header>
                    <Table.Row>
                        {headers.weekDays.map(({ key, value }) => {
                            return (
                                <Table.ColumnHeaderCell key={key}>
                                    {weekdays[value.getDay()].substring(0, 3)}
                                </Table.ColumnHeaderCell>
                            );
                        })}
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    {body.value.map(({ key, value: days }) => (
                        <Table.Row key={key}>
                            {days.map(({ key, value }) => {
                                const firstDateOfMonth = subHours(
                                    new Date(format(currentDate, 'yyyy-MM-01')),
                                    1
                                );
                                const lastDateOfMonth = subSeconds(
                                    addDays(lastDayOfMonth(currentDate), 1),
                                    1
                                );
                                const isThisMonth = isWithinInterval(value, {
                                    start: firstDateOfMonth,
                                    end: lastDateOfMonth,
                                });
                                const isSelected = selected?.find(
                                    (sel) =>
                                        format(sel.date, 'yyyy-MM-dd') ===
                                        format(value, 'yyyy-MM-dd')
                                );

                                const classes = classNames({
                                    CalendarCurrentMonth: isThisMonth,
                                    // CalendarOutsideMonth: !isThisMonth,
                                    CalendarSelected: isSelected ? true : false,
                                    ['CalendarSelectedCouncil']:
                                        isSelected?.funding == 'Council' ? true : false,
                                });
                                return (
                                    <Table.Cell
                                        className={classes}
                                        key={key}
                                        onClick={() =>
                                            isThisMonth &&
                                            onChange(name, format(value, 'yyyy-MM-dd'))
                                        }
                                    >
                                        {value.getDate()}
                                    </Table.Cell>
                                );
                            })}
                        </Table.Row>
                    ))}
                </Table.Body>
            </Table.Root>
        </>
    );
}

export default Calendar;
