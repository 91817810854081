import { Box, Card, Text } from '@radix-ui/themes';
import classNames from 'classnames';
import '../styles/ColourCard.css';

function ColourCard({ colour, label }) {
    const classess = classNames({
        ColourKeyBlockColour: true,
        ColourKeyBlockColourPink: colour === 'Pink',
        ColourKeyBlockColourBlue: colour === 'Blue',
    });

    return (
        <Card variant="surface" className="ColourKeyItem">
            <Box className={classess}></Box>
            <Box>
                <Text as="div" size="2">
                    {label}
                </Text>
            </Box>
        </Card>
    );
}

export default ColourCard;
