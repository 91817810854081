import { Box, Heading, Text } from '@radix-ui/themes';
import '../styles/Error.css';

function Error({ txt }) {
    return (
        <Box className="ErrorBox" p="4">
            <Heading className="ErrorHeading">Error</Heading>
            <Text className="ErrorText">{txt}</Text>
        </Box>
    );
}

export default Error;
