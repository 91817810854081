import { SignIn, SignedIn, SignedOut, useUser } from '@clerk/clerk-react';
import { Flex } from '@radix-ui/themes';
import MenuItem from './components/MenuItem';
import './styles/Home.css';

function Home() {
    const { user } = useUser();

    return (
        <>
            <SignedOut>
                <img className="logo" src="/st-catherines-church-logo.png" alt="logo" />
                Customer Database
                <SignIn />
            </SignedOut>
            <SignedIn>
                <div className="hello">Hello {user?.fullName}!</div>

                <Flex gap="3">
                    <MenuItem
                        img="/customers.jpg"
                        alt="Manage customers"
                        url="/customers"
                        txt="Manage your customer records"
                    />
                    <MenuItem
                        img="/attendance.jpg"
                        alt="Manage attendance"
                        url="/attendance"
                        txt="Customer attendance"
                    />
                    <MenuItem
                        img="/reports.jpg"
                        alt="Reports and Charts"
                        url="/reports"
                        txt="View reports and charts"
                    />
                    <MenuItem
                        img="/exports.jpg"
                        alt="Export data"
                        url="/exports"
                        txt="Export data to excel"
                    />
                    {/* <MenuItem img="/accounts.jpg" alt="Accounts" url="/accounts" txt="Accounting data" /> */}
                </Flex>
            </SignedIn>
        </>
    );
}

export default Home;
